import React, { useContext } from "react";
import ModelBCategoryGroup from "./ModelBCategoryGroup";
import ModelBElasticityGroup from "./ModelBElasticityGroup";
import ElasticityRadioGroup from "./ElasticityRadioGroup";
import SelectNutientsRadioGroup from "./SelectNutientsRadioGroup";
import { FormBContext } from "../../../context/FormBContext";
import { NutrientType } from "../../../api/modelBObjects";
import { TaxesGroup } from "./TaxesGroup";
import { Box, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

export const InputsContainer = () => {
    const { selectedNutrient,
        sugarTaxes: tieredTax_sugar, setSugarTaxes: setTieredTax_sugar,
        saltTaxes: tieredTax_salt, setSaltTaxes: setTieredTax_salt
    } = useContext(FormBContext);

    const { t } = useTranslation();

    if (true) return <Grid container columnSpacing='24px' minHeight='910px'>
        <Grid item xs={6}>
            <Stack gap='24px' height='100%'>
                {
                    selectedNutrient === NutrientType.Salt &&
                    <Paper variant='outlined' sx={{
                        width: '100%',
                        padding: '24px',
                        borderRadius: '12px',
                        border: '1px solid #797979',
                        boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2)',
                    }}>
                        <Typography sx={{
                            fontSize: '20px',
                            fontWeight: '600',
                            lineHeight: '24px',
                            textAlign: 'left',
                            color: '#262626',
                            mb: "8px"

                        }}>{t(`sideBar.${NutrientType.Salt}`) + " " + t(`sideBar.taxes`)}</Typography>

                        <Divider variant='fullWidth' />

                        <Box mt='24px'>
                            <TaxesGroup
                                taxes={tieredTax_salt}
                                setTaxes={setTieredTax_salt}
                                nutrientType={NutrientType.Salt}
                            />
                        </Box>
                    </Paper>
                }

                {
                    selectedNutrient === NutrientType.Sugar &&
                    <Paper variant='outlined' sx={{
                        width: '100%',
                        padding: '24px',
                        borderRadius: '12px',
                        border: '1px solid #797979',
                        boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2)',
                    }}>
                        <Typography sx={{
                            fontSize: '20px',
                            fontWeight: '600',
                            lineHeight: '24px',
                            textAlign: 'left',
                            color: '#262626',
                            mb: "8px"

                        }}>{t(`sideBar.${NutrientType.Sugar}`) + " " + t(`sideBar.taxes`)}</Typography>

                        <Divider variant='fullWidth' />

                        <Box mt='24px'>

                            <TaxesGroup
                                taxes={tieredTax_sugar}
                                setTaxes={setTieredTax_sugar}
                                nutrientType={NutrientType.Sugar}
                            />

                        </Box>
                    </Paper>
                }
                <Paper variant='outlined' sx={{
                    width: '100%',
                    height: '100%',
                    padding: '24px',
                    borderRadius: '12px',
                    border: '1px solid #797979',
                    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2)',
                }}>
                    <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '600',
                        lineHeight: '24px',
                        textAlign: 'left',
                        color: '#262626',
                        mb: "8px"

                    }}>Select elasticities</Typography>

                    <Divider variant='fullWidth' />
                    <Box mt='24px'>
                        <ElasticityRadioGroup />
                        <ModelBElasticityGroup />
                    </Box>
                </Paper>
            </Stack>
        </Grid>
        <Grid item xs={6}>
            <Stack gap='24px' height='100%'>

                <Paper variant='outlined' sx={{
                    width: '100%',
                    height: '188px',
                    padding: '24px',
                    borderRadius: '12px',
                    border: '1px solid #797979',
                    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2)',
                }}>
                    <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '600',
                        lineHeight: '24px',
                        textAlign: 'left',
                        color: '#262626',
                        mb: "8px"

                    }}>Select nutrients</Typography>

                    <Divider variant='fullWidth' />
                    <Box mt='24px'>
                        <SelectNutientsRadioGroup />
                    </Box>
                </Paper>

                <Paper variant='outlined' sx={{
                    width: '100%',
                    height: '100%',
                    padding: '24px',
                    borderRadius: '12px',
                    border: '1px solid #797979',
                    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2)',
                    // m: "auto"
                }}>
                    <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '600',
                        lineHeight: '24px',
                        textAlign: 'left',
                        color: '#262626',
                        mb: "8px"
                    }}>Select categories</Typography>

                    <Divider variant='fullWidth' />
                    <Box mt='24px' height='600px'>
                        <ModelBCategoryGroup />
                    </Box>
                </Paper>
            </Stack>
        </Grid>
    </Grid>
}
