import React, { useContext } from 'react';
import { orangeBlackGroupColor } from '../../components/ChartColors';
import { FormBContext } from '../../context/FormBContext';
import { useTranslation } from 'react-i18next';
import BarChart from '../../widgets/BarChart';
import Tooltip from '../../components/Tooltip';


function CoreChart() {
    const { t } = useTranslation();

    const { results } = useContext(FormBContext)
    if (results && results.IngredientAmountsData) {
        const ingredientAmounts = results.IngredientAmountsData
            .map(d => {
                return {
                    ...d,
                    label: t("modelB.categories." + d.label)
                };
            });

        return <BarChart
            data={ingredientAmounts}
            keys={[
                'Sugar',
                'Salt'
            ]}
            indexBy="label"
            margin={{ top: 0, right: 90, bottom: 0, left: 230 }}
            padding={0.15}
            groupMode="grouped"
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={orangeBlackGroupColor}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
            }}
            enableGridX={false}
            enableGridY={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={'white'}
            label={d => d.value && Math.abs(d.value) > 0.75 ? `${d.value.toFixed(2)}`: ''}

            theme={{
                fontSize:16,
                textColor:'#474747',
            }}

            tooltip={e =>
                <Tooltip>
                    {e.id ? `${e.id} - ${e.indexValue}: ` : ""}
                    {e.value
                        ? e.value.toFixed(2)
                        : "No data"}
                </Tooltip>
            }
        />
    }
    else {
        return <div> No data </div>;
    }
}

export const ProductCategoriesByAverageChart = () => {
    return <div style={{ width: "100%" , position: "relative" , display:'flex' , flexDirection: 'column' , gap:'24px'  }}>
        <div style={{ height: "600px"}}> 
        <CoreChart />
        </div>
        <div className='legend'>
            <div className='rect orangeRect'></div> <span>Sugar</span>
            <div className='rect blackRect'></div><span>Salt</span>
        </div>
    </div>
}
