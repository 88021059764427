import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormBContext } from "../../context/FormBContext";
import { europeCountries } from "./EuropeCountries";
import "./LeafMap.scss";
import { worldCountries } from "./WorldCountries";
import geojson from "geojson";
import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";

const returnCountryValue = (countryToCheck: string, enabledCountries: Array<string>, selectedCoutry: string | null): number => {
    if (countryToCheck === selectedCoutry) {
        return 2;
    } else {
        return enabledCountries.includes(countryToCheck) ? 1 : 0;
    }
};

const updateFeaturesForEuroCountries = (countries: Array<string>, selectedCountry: string | null) => {
    return [
        ...worldCountries.features
            .filter((f) => Object.keys(europeCountries).includes(f.id))
            .map((f) => {
                return { ...f, value: returnCountryValue(f.id, countries, selectedCountry) };
            }),
    ];
};

export const MapsB = () => {
    const contextB = useContext(FormBContext);
    const { isDisabled, country, countries, setCountry, setIsCountrySelected } = contextB;

    // @ts-ignore
    const [features, setFeatures] = useState<geojson.FeatureCollection>(updateFeaturesForEuroCountries(countries, country));
    useEffect(() => {
        // @ts-ignore
        setFeatures(updateFeaturesForEuroCountries(countries, country));
    }, [countries, country]);

    const handleCountrySelect = (e: SelectChangeEvent) => {
        setCountry(e.target.value);
        setIsCountrySelected(true);
    };

    const filteredCountries = useCallback(() => {
        // @ts-ignore
        return Array.from(features.filter(({ id }) => countries.includes(id))).sort((a, b) => {
            // @ts-ignore
            const countryA = a.properties.name.toUpperCase(); // Convert names to uppercase for case-insensitive comparison
            // @ts-ignore
            const countryB = b.properties.name.toUpperCase();
            
            if (countryA < countryB) {
                return -1; 
            }
            if (countryA > countryB) {
                return 1;
            }
            return 0; 
        })
    }, [countries, features])

    return (
        <Stack gap="8px">
            <Typography
                sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "19px",
                    textAlign: "left",
                    color: "#363636",
                }}
            >
                Select country
            </Typography>
            <Select
                variant="outlined"
                fullWidth
                disabled={isDisabled}
                value={country}
                style={{ width: "100%", maxWidth: "330px" }}
                onChange={handleCountrySelect}
                displayEmpty
                inputProps={{ "aria-label": "Select country" }} 
            >
                {!country && (
                    <MenuItem disabled value="" sx={{color:'rgba(0, 0, 0, 0.38)'}}>
                        Select country
                    </MenuItem>
                )}
                {features &&
                    filteredCountries().map((option, id: number) => (
                        // @ts-ignore
                        <MenuItem key={id} value={option.id}>
                            {/* @ts-ignore */}
                            {option.properties.name}
                        </MenuItem>
                    ))}
            </Select>
        </Stack>
    );
};
