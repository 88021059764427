import React, { useState, createContext, useEffect, useCallback } from "react";
import { NutrientType, ModelBType, defaultSugarTaxes, defaultSaltTaxes, FrontEndLevies } from "../api/modelBObjects";

export type LabelSugarSalt = {
    label: string;
    Sugar: number;
    Salt: number;
};

export type LabelTypeValueTyped = {
    label: string;
    type: string;
    value: number;
};

export type LabelTaxTypeValueTyped = {
    label: string;
    taxType: string;
    type?: string;
    value: number;
};
export type NameTaxTypeValueTyped = {
    name: string;
    taxType: string;
    value: number;
};

export type LabelNoTaxSugarTaxSaltTax = {
    label: string;
    "No Tax": number;
    "Sugar Tax": number;
    "Salt Tax": number;
    increase_absolute: number;
    increase_percent: number;
};

export type LabelSaltTaxSugarTax = {
    category: string;
    "Salt Tax": number;
    "Sugar Tax": number;
};

export type LabelVatExciseTaxChange = {
    label: string;
    VAT: number;
    "Excise Tax Sugar": number;
    "Excise Tax Salt": number;
    total: number;
    increase_absolute: number;
    increase_percent: number;
};

export type LabelPrices = {
    label: string;
    "No Tax": number;
    "Sugar Tax": number;
    total: number;
    increase_absolute: number;
    increase_percent: number;
};

type Results = {
    IngredientAmountsData: LabelSugarSalt[] | null;
    baseLinePricesStatusQuoData: LabelNoTaxSugarTaxSaltTax[] | null;
    baseLinePricesNewLevyData: LabelNoTaxSugarTaxSaltTax[] | null;
    averagePriceChangeData: unknown[] | null;
    volumeChangeData: LabelSaltTaxSugarTax[] | null;
    averageChangeGroupData: unknown[] | null;
    taxRevenueData: LabelVatExciseTaxChange[] | null;
};

type RawData = any;

export type ContexBType = {
    countries: Array<string>;

    country: string;
    setCountry: (a: string) => void;

    isCountrySelected: boolean;
    setIsCountrySelected: (a: boolean) => void;

    categoryOptions: Array<string>;
    setCategoryOptions: (categories: Array<string>) => void;

    categorySelection: Array<string>;
    setCategorySelection: (categories: Array<string>) => void;

    elasticityCategorySelection: Array<string>;

    defaultElasticities: Map<string, number>;
    setDefaultElasticities: (elasticityList: Map<string, number>) => void;

    customElasticities: Map<string, number>;
    setCustomElasticities: (customElasticities: Map<string, number>) => void;

    overrideElasticity: boolean;
    setOverrideElasticity: (overrideElasticity: boolean) => void;

    vat: number | null;
    setVat: (a: number | null) => void;

    overrideVat: boolean;
    setOverrideVat: (overrideVat: boolean) => void;

    sugarTaxes: FrontEndLevies;
    setSugarTaxes: (a: FrontEndLevies) => void;

    saltTaxes: FrontEndLevies;
    setSaltTaxes: (a: FrontEndLevies) => void;

    selectedNutrient: NutrientType;
    setSelectedNutrient: (nutrient: NutrientType) => void;

    isDisabled: boolean;
    setIsDisabled: (a: boolean) => void;

    getFormData: () => ModelBType;

    rawData: RawData | null;
    setRawData: (data: RawData | null) => void;
    results: Results | null;
    setResults: (data: Results | null) => void;

    areInputsInvalid: boolean;
    setAreInputsInvalid: (a: boolean) => void;
};

type Props = {
    children: any;
};

export const FormBContext = createContext<ContexBType>({
    countries: [],
    country: "",
    setCountry() {},
    isCountrySelected: false,
    setIsCountrySelected() {},
    categoryOptions: [],
    setCategoryOptions() {},
    categorySelection: [],
    setCategorySelection() {},
    elasticityCategorySelection: [],
    defaultElasticities: new Map<string, number>(),
    setDefaultElasticities() {},
    customElasticities: new Map<string, number>(),
    setCustomElasticities() {},
    overrideElasticity: false,
    setOverrideElasticity() {},
    vat: 0,
    setVat() {},
    overrideVat: false,
    setOverrideVat() {},
    sugarTaxes: defaultSugarTaxes,
    setSugarTaxes() {},
    saltTaxes: defaultSaltTaxes,
    setSaltTaxes() {},
    selectedNutrient: NutrientType.Sugar,
    setSelectedNutrient() {},
    isDisabled: false,
    setIsDisabled() {},
    getFormData(): ModelBType {
        return {
            country: "",
            categories: [],
            elasticity: new Map(),
            vat: 0,
            sugar_taxes: { base: 0, tiers: [] },
            salt_taxes: { base: 0, tiers: [] },
        };
    },
    rawData: null,
    setRawData() {},
    results: null,
    setResults() {},
    areInputsInvalid: false,
    setAreInputsInvalid() {},
});

const FormBContextProvider = (props: Props) => {
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [isCountrySelected, setIsCountrySelected] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState<Array<string>>([]);
    const [categorySelection, setCategorySelection] = useState<Array<string>>([]);
    const [elasticityCategorySelection, setElasticityCategorySelection] = useState<Array<string>>([]);
    const [elasticityCategoryDictionary, setElasticityCategoryDictionary] = useState(new Map());
    const [defaultElasticities, setDefaultElasticities] = useState(new Map());
    const [customElasticities, setCustomElasticities] = useState(new Map());
    const [overrideElasticity, setOverrideElasticity] = useState(false);
    const [vat, setVat] = useState<number | null>(0);
    const [overrideVat, setOverrideVat] = useState(false);
    const [saltTaxes, setSaltTaxes] = useState(defaultSaltTaxes);
    const [sugarTaxes, setSugarTaxes] = useState(defaultSugarTaxes);
    const [selectedNutrient, setSelectedNutrient] = useState(NutrientType.Sugar);
    const [isDisabled, setIsDisabled] = useState(false);
    const [rawData, setRawData] = useState<RawData | null>(null);
    const [results, setResults] = useState<Results | null>(null);
    const [areInputsInvalid, setAreInputsInvalid] = useState(false);

    const getFormData = () => {
        const getTax = (nutrient: NutrientType) => {
            if (nutrient !== selectedNutrient) {
                return { base: 0, tiers: [] };
            }
            if (nutrient === NutrientType.Sugar) {
                return { base: sugarTaxes.base, tiers: [...sugarTaxes.tiers.values()] };
            }
            if (nutrient === NutrientType.Salt) {
                return { base: saltTaxes.base, tiers: [...saltTaxes.tiers.values()] };
            }
            return { base: 0, tiers: [] };
        };
        return {
            country: country,
            categories: categorySelection,
            vat: overrideVat ? vat : null,
            elasticity: overrideElasticity ? customElasticities : defaultElasticities,
            sugar_taxes: getTax(NutrientType.Sugar),
            salt_taxes: getTax(NutrientType.Salt),
        };
    };

    let initialValuesForCustomElasticities = (data: Map<string, number>) => {
        const objectArr = Object.keys(data).map((d) => {
            return { [d]: 0 };
        });
        let elasticityDefaultValues = new Map<string, number>();
        objectArr.forEach((a) => {
            elasticityDefaultValues = {
                ...elasticityDefaultValues,
                [Object.keys(a)[0]]: 0,
            };
        });
        return elasticityDefaultValues;
    };

    const handleProductCategoryChange = useCallback(
        (categories: Array<string>) => {
            let enableElasticiyCategory: Array<string> = [];
            categories.forEach((ca) => {
                Object.keys(elasticityCategoryDictionary).forEach((key) => {
                    if (key.toLowerCase() === ca.toLowerCase()) {
                        let x = elasticityCategoryDictionary[ca];
                        enableElasticiyCategory.push(x);
                    }
                });
            });
            setCategorySelection(categories);
            setElasticityCategorySelection(enableElasticiyCategory);
        },
        [elasticityCategoryDictionary]
    );

    useEffect(() => {
        fetch("/api/modelb/countries")
            .then((r) => r.json())
            .then((c) => {
                setCountries(c);
            });

        fetch("/api/modelb/products")
            .then((r) => r.json())
            .then((po) => {
                setCategoryOptions(Object.keys(po));
                setCategorySelection(Object.keys(po));
                setElasticityCategorySelection(Object.values(po));
                setElasticityCategoryDictionary(po);
            });

        fetch("/api/modelb/elasticities")
            .then((r) => r.json())
            .then((po) => {
                setDefaultElasticities(po);
                setCustomElasticities(initialValuesForCustomElasticities(po));
            });
    }, []);

    const elasticitiesContainNull = useCallback((): boolean => {
        return overrideElasticity && Object.values(customElasticities).some((v) => v === null);
    }, [customElasticities, overrideElasticity]);

    const taxesContainsNull = useCallback((saltTaxes, selectedNutrient, sugarTaxes): boolean => {
        if (selectedNutrient === NutrientType.Sugar) {
            return sugarTaxes.base === null || [...sugarTaxes.tiers.values()].some((v) => v.rate === null || v.threshold === null);
        } else if (selectedNutrient === NutrientType.Salt) {
            return saltTaxes.base === null || [...saltTaxes.tiers.values()].some((v) => v.rate === null || v.threshold === null);
        }
        return false;
    }, []);

    useEffect(() => {
        // check country, categorySelection, vat, elasticity, taxes
        setAreInputsInvalid(
            !isCountrySelected ||
                categorySelection.length === 0 ||
                (overrideVat && vat == null) ||
                elasticitiesContainNull() ||
                taxesContainsNull(saltTaxes, selectedNutrient, sugarTaxes)
        );
    }, [
        isCountrySelected,
        categorySelection,
        overrideVat,
        vat,
        elasticitiesContainNull,
        taxesContainsNull,
        saltTaxes,
        sugarTaxes,
        selectedNutrient,
        customElasticities,
        overrideElasticity,
    ]);

    return (
        <FormBContext.Provider
            value={{
                countries,
                country,
                setCountry,
                isCountrySelected,
                setIsCountrySelected,
                categoryOptions,
                setCategoryOptions,
                categorySelection,
                setCategorySelection: handleProductCategoryChange,
                elasticityCategorySelection,
                defaultElasticities,
                setDefaultElasticities,
                customElasticities,
                setCustomElasticities,
                overrideElasticity,
                setOverrideElasticity,
                vat,
                setVat,
                overrideVat,
                setOverrideVat,
                sugarTaxes,
                setSugarTaxes,
                saltTaxes,
                setSaltTaxes,
                selectedNutrient,
                setSelectedNutrient,
                isDisabled,
                setIsDisabled,
                getFormData,
                rawData,
                setRawData,
                results,
                setResults,
                areInputsInvalid,
                setAreInputsInvalid,
            }}
        >
            {props.children}
        </FormBContext.Provider>
    );
};

export default FormBContextProvider;
