import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ModelBCategoryGroup.scss';
import { FormBContext } from '../../../context/FormBContext';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';

import BpCheckedIcon from '../../Inputs/BpCheckedIcon';
import BpIcon from '../../Inputs/BpIcon';
import { categoriesOrder } from './CategoriesOrder';

function createOptions(categoryOptions: string[], categorySelection: string[]) {
    // Filter out categories that don't exist in the model
    const validCategoryOptions = categoriesOrder.filter((cat) => categoryOptions.includes(cat));
    const validSelectedOptions = categoriesOrder.filter((cat) => categorySelection.includes(cat));

    return validCategoryOptions.map((option) => { return { value: option, include: validSelectedOptions.includes(option) }; });
}

const ModelBCategoryGroup = () => {
    const { t } = useTranslation();
    const { categoryOptions, categorySelection, setCategorySelection, isDisabled } = useContext(FormBContext);
    const [options, setOptions] = useState(createOptions(categoryOptions, categorySelection));

    const handleCheckBoxChange = (i, isChecked) => {
        const newOptions = [...options];
        newOptions[i].include = isChecked;
        setOptions(newOptions);
        setCategorySelection(newOptions.filter(c => c.include).map(c => c.value))
    }

    const handleSelectAllChange = (isChecked) => {
        const newOptions = [...options];
        for (const element of newOptions) {
            element.include = isChecked;
        }
        setOptions(newOptions);
        setCategorySelection(newOptions.filter(c => c.include).map(c => c.value))
    }

    useEffect(() => {
        setOptions(createOptions(categoryOptions, categorySelection))
    }, [categoryOptions, categorySelection])

    return <div className='modelBCategoryGroupComponent'>
        <FormGroup sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
            flex:1 
        }}>
             <FormControlLabel key="selectAll"
                sx={{ fontWeight: "bold", width: '100%' }}
                control={
                    <Checkbox sx={{
                        color: red[800],
                        '&.Mui-checked': {
                            color: red[600],
                        },
                        "&.MuiCheckbox-root": {
                            borderRadius: '3px',
                            width: '18px',
                            height: '18px',
                            boxShadow: 'inset 0 0 0 2px rgba(0, 0, 0, 0.6), inset 0 -1px 0 rgba(0, 0, 0, 0.6)',
                            backgroundColor: '#fff',
                            margin:'12px'
                        }
                    }}
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        disabled={isDisabled}
                        defaultChecked
                        onChange={(e) => handleSelectAllChange(e.target.checked)}
                    />
                }
                label={t("sideBar.selectAll")}
            />
            {options.map((c, i) => {
                return <FormControlLabel key={i}
                    sx={{ position: 'relative'}}
                    control={
                        <div>
                            <Checkbox sx={{
                                color: red[800],
                                '&.Mui-checked': {
                                    color: red[600],
                                },
                                "&.MuiCheckbox-root": {
                                    borderRadius: '3px',
                                    width: '18px',
                                    height: '18px',
                                    boxShadow: 'inset 0 0 0 2px rgba(0, 0, 0, 0.6), inset 0 -1px 0 rgba(0, 0, 0, 0.6)',
                                    backgroundColor: '#fff',
                                    margin:'12px'
                                }
                            }}
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                                disabled={isDisabled}
                                className={c.include ? 'selected checkbox' : 'checkbox'}
                                checked={c.include}
                                onChange={(e) => handleCheckBoxChange(i, e.target.checked)}
                                name={c.value} />

                        </div>
                    }
                    label={t("modelB.categories." + c.value)}
                />
            })
            }
        </FormGroup>
    </div>
};

export default ModelBCategoryGroup;
