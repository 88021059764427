import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { IsNumeric } from "../../utils/NumberValidation";

interface Props {
    value: number | null,
    disabled?: boolean,
    name?: string,
    onChange: (value: number | null) => void,
    digits?: number,
    forceUpdate?: number,
    isPositive?: boolean,
    isNonZero?: boolean,
    required?: boolean,
}

export function NumberInput(props: Props) {
    const { value, disabled = false, name = '', onChange, digits = 2, forceUpdate = 0, isPositive = false, isNonZero = false, required = true } = props;
    const [displayValue, setDisplayValue] = useState(value !== null ? value.toFixed(digits) : "");
    const [helperText, setHelperText] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        if (value) {
            setDisplayValue(value.toFixed(digits));
        }

        // This is intentional, we don't want to change the dispayed value unless there is a forced update
        // All the other dependencies like value and digits should NOT be taken into account here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceUpdate]);

    return <TextField
        id="standard-adornment-amount"
        className="sInput"
        size="small"
        variant="filled"
        helperText={helperText}
        value={displayValue}
        disabled={disabled}
        error={error}
        required={required}
        name={name}
        onFocus={() => {
            if (!error && value != null) {
                // show actual value with more (or less) decimals if present
                setDisplayValue(value.toString())
            }
        }}
        onChange={(e) => {
            if (!IsNumeric(e.target.value)) {
                setError(true);
                setHelperText("Non-numerical value");
            }
            else if (isNonZero && Number(e.target.value) === 0) {
                setError(true);
                setHelperText("May not be 0");
            }
            else if (isPositive && Number(e.target.value) < 0) {
                setError(true);
                setHelperText("Must be positive");
            }
            else {
                setError(false);
                setHelperText("");
            }
            setDisplayValue(e.target.value);
        }}
        onBlur={() => {
            const val = Number(displayValue);
            if (!error) {
                // update display string to show only <digits> decimals, but keep value static
                setDisplayValue(val.toFixed(digits))
                onChange(val);
            }
            else {
                onChange(null);
            }
        }}
    />;
}

