import React from "react";
import { IconButton, Tooltip } from "@mui/material"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
export const ExportChartButton = ({ handleExport }) => {
    return <IconButton
        className="exportBtn"
        color='error'
        onClick={handleExport}>
        <Tooltip
            placement="top"
            title="Export PDF">
            <FileDownloadIcon />
        </Tooltip>
    </IconButton>
}
