import React, { useEffect } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import * as Paths from './Paths';

import './App.scss';
import Home from './pages/Home';
import ModelA from './pages/ModelA/ModelA';
import ModelB from './pages/ModelB/ModelB';
import { Version } from './pages/Version';

function RequiresLogin() {
    useEffect(function checkLoggedIn() {
        fetch(Paths.loggedin.path)
            .then(response => response.json())
            .then(loggedIn => {
                if (!loggedIn) {
                    document.location.href = Paths.login.path;
                }
            });
    });
    return <Outlet />;
}

function App() {
    return (
        <BrowserRouter>
            <Routes >
                <Route path="/" element={<RequiresLogin />}>
                    <Route path={Paths.root.path} element={<Home />} />
                    <Route path={Paths.ppt.path} element={<ModelA />} />
                    <Route path={Paths.bbta.path} element={<ModelB />} />
                    <Route path={Paths.version.path} element={<Version />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
