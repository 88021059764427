import Button from '@mui/material/Button';
import React, { useContext } from 'react';
import { FormAContext } from '../../context/FormAContext';
import { useTranslation } from 'react-i18next';
import './CalculateButton.scss';

type Props = {
    setIsCalculated: (isCalculated: boolean) => void,
}
export const CalculateButtonA = (props: Props) => {
    const { setIsCalculated } = props;
    const { setIsDisabled, getFormData, setResults, isCountrySelected, areInputsInvalid } = useContext(FormAContext);

    const { t } = useTranslation();

    const handleClick = event => {
        event.preventDefault();
        setIsDisabled(true);
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(getFormData())
        };

        fetch("api/modela", requestOptions)
            .then(r => r.json())
            .then(response => {
                setResults(response);
            })
            .catch(error => console.log('Form submit error', error))

        setIsCalculated(true);
    };

    return <>
        {areInputsInvalid && <span className='inputNotification'>Please fill all inputs to activate show results button</span>}
        <Button disabled={areInputsInvalid} variant='contained' className={'calculate'} onClick={(e) => {
            if (isCountrySelected) { handleClick(e); }
            else { alert(t('modelA.selectCountryNotification')) }
        }}>{t('modelA.showResults')}</Button>
    </>
};
