import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { LevyTier, ModelBType, NutrientType, VariableLevies } from "../api/modelBObjects";

export const HandleChart = (data: ModelBType, chartId: string, currencyString: string) => {

    let lineY = 0;
    const incrementLine = () => { return lineY += 20; };

    const drawTaxes = (pdf: jsPDF, nutrient: NutrientType, taxes: VariableLevies) => {
        const capitalizedNutrient = nutrient.toString()[0].toUpperCase() + nutrient.toString().slice(1)
        if (taxes.tiers.length === 0) {
            // flat tax
            pdf.text(`${capitalizedNutrient} Flat Tax: ${currencyString} ${taxes.base}`, 30, incrementLine());
        }
        if (taxes.tiers.length > 0) {
            pdf.text(`${capitalizedNutrient} Taxes:`, 30, incrementLine());
            // first tier is using base
            pdf.text(`< ${taxes.tiers[0].threshold}: ${currencyString} ${taxes.base}`, 30, incrementLine());
            taxes.tiers.forEach((tier: LevyTier, index: number) => {
                if (index < taxes.tiers.length - 1) {
                    // intermediate tiers
                    pdf.text(`${tier.threshold} - ${taxes.tiers[index + 1].threshold}: ${currencyString} ${tier.rate}`, 30, incrementLine());
                }
                else {
                    // last tier is greater than
                    pdf.text(`${taxes.tiers[taxes.tiers.length - 1].threshold} <: ${currencyString} ${taxes.tiers[taxes.tiers.length - 1].rate}`, 30, incrementLine());
                }
            })
        }
    }

    const input = document.getElementById(chartId);
   
    if (input) {
        html2canvas(input, { 
            scale: ['productCategoriesDescription' , 'baselinePricesDescription' , 'taxRevenuesDescription'].includes(chartId) ? 0.5: 0.87 })
            .then((canvas) => {
                const pdf = new jsPDF('p', 'px', 'A4');
                const imgData = canvas.toDataURL('image/png');

                pdf.addImage(imgData, 'PNG', 1, 30, 0, 0);

                pdf.addPage()

                // first column at x = 30
                lineY = 50;
                pdf.text("INPUT DETAILS", 30, incrementLine());
                pdf.setFontSize(14);
                pdf.text(`Country: ${data.country}`, 30, incrementLine());
                pdf.text(`Elasticities:`, 30, incrementLine());
                pdf.text(`Beverages: ${data.elasticity['Beverages']}`, 30, incrementLine());
                pdf.text(`Breakfast cereals: ${data.elasticity['Breakfast cereals']}`, 30, incrementLine());
                pdf.text(`Meat & proteins: ${data.elasticity['Meat & proteins']}`, 30, incrementLine());
                pdf.text(`Oils & fats: ${data.elasticity['Oils & fats']}`, 30, incrementLine());
                pdf.text(`Other food categories: ${data.elasticity['Other food categories']}`, 30, incrementLine());
                pdf.text(`Processed fruits & vegetables: ${data.elasticity['Processed fruits & vegetables']}`, 30, incrementLine());
                pdf.text(`Dairy: ${data.elasticity['Dairy']}`, 30, incrementLine());

                drawTaxes(pdf, NutrientType.Sugar, data.sugar_taxes);
                drawTaxes(pdf, NutrientType.Salt, data.salt_taxes);

                // second column at x = 300
                lineY = 50;
                pdf.text(`Selected Categories: `, 300, incrementLine());
                data.categories.forEach((c: any) => {
                    return pdf.text(`${c}`, 300, incrementLine());
                })
                pdf.save("download.pdf");
            });
    }
}
