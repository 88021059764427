import React from "react"
import { Header } from "../widgets/Header"
import Footer from "../widgets/Footer"
import { Link, Typography, styled } from "@mui/material"

interface ILayoutPanelProps {
    children: React.ReactNode,
    bg?: String
}

const AppWrapper = styled('div')({
    width:'100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowX:'hidden'
})

const Main = styled('main')({
    flex: '1',
    display: 'flex',
    flexDirection:'column',
    justifyContent:'space-between'
})


const LayoutPanel = ({children , bg}:ILayoutPanelProps) => {
    return <AppWrapper>
        <Header />

        <Main className={`${bg}`}>
            {children}
        <Typography sx={{
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '19px',
            textAlign: 'center',
            color:'#262626',
            p:'48px',
            
        }}>Sources and methodology: <Link sx={{color:'#182FFE' , textDecoration:'none' , cursor:'pointer'}} href={require("../assets/Methodology 2024 update.pdf")} download="Broad_Based_Sugar_Taxes_Methodology_SRQ.pdf">Download pdf</Link></Typography>
        </Main>
        <Footer />
    </AppWrapper>
}

export default LayoutPanel
