import Button from "@mui/material/Button";
import React, { useContext, useState } from "react";
import {
    FormBContext,
    LabelNoTaxSugarTaxSaltTax,
    LabelSaltTaxSugarTax,
    LabelSugarSalt,
    LabelTaxTypeValueTyped,
    LabelTypeValueTyped,
    LabelVatExciseTaxChange,
    NameTaxTypeValueTyped,
} from "../../context/FormBContext";
import { useTranslation } from "react-i18next";
import "./CalculateButton.scss";
import { NutrientType } from "../../api/modelBObjects";

type Props = {
    setIsCalculated: (isCalculated: boolean) => void;
};

function InputWarning() {
    const { t } = useTranslation();
    const { isCountrySelected, categorySelection, areInputsInvalid } = useContext(FormBContext);

    if (!isCountrySelected) {
        return <span className="inputNotification">{t("modelB.selectCountryNotification")}</span>;
    } else if (categorySelection.length === 0) {
        return <span className="inputNotification">{t("modelB.selectCategoryNotification")}</span>;
    } else if (areInputsInvalid) {
        return <span className="inputNotification">{t("modelB.invalidInputsNotification")}</span>;
    } else {
        return null;
    }
}

export const CalculateButtonB = (props: Props) => {
    const { setIsCalculated } = props;
    const { setIsDisabled, getFormData, setRawData, setResults, categorySelection, areInputsInvalid, selectedNutrient } = useContext(FormBContext);
    const { t } = useTranslation();
    const [calculating, setCalculating] = useState(false);

    const handleClick = (event) => {
        event.preventDefault();
        if (categorySelection.length > 0) {
            setIsDisabled(true);
            setCalculating(true);
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(getFormData()),
            };

            fetch("api/modelb", requestOptions).then((response) => {
                setCalculating(false);
                const resData = response.json();
                resData.then((data) => {
                    setRawData(data);
                    const zipped = zipData(data, selectedNutrient);
                    setResults(zipped);
                    setIsCalculated(true);
                });
            });
        } else alert();
    };

    return (
        <>
            <InputWarning />
            <Button disabled={areInputsInvalid} variant="contained" className={"calculate calculateB"} onClick={handleClick}>
                {calculating ? t("sideBar.calculatingButton") : t("sideBar.calculateButton")}
            </Button>
        </>
    );
};

function zipData(data: any, selectedNutrient: NutrientType) {
    const zippedIA = zipIngredientAmount(data.IngredientAmountsData);
    const zippedBP = zipBaselineStatusQuoPrice(data.baseLinePricesDataExisting, selectedNutrient);
    const zippedNBP = zipBaselineNewLevyPrice(data.baseLinePricesDataExcise, selectedNutrient);
    const zippedAPC = zipAveragePriceChange(data.averagePriceChangeData, selectedNutrient);
    const zippedVC = zipVolumeChange(data.volumeChangeData, selectedNutrient);
    const zippedACG = zipAverageChangeGroup(data.averageChangeGroupData, selectedNutrient);
    const zippedTR = zipTaxRevenue(data.taxRevenueData, selectedNutrient);
    return {
        IngredientAmountsData: zippedIA,
        baseLinePricesStatusQuoData: zippedBP,
        baseLinePricesNewLevyData: zippedNBP,
        averagePriceChangeData: zippedAPC,
        volumeChangeData: zippedVC,
        averageChangeGroupData: zippedACG,
        taxRevenueData: zippedTR,
    };
}

// tiny delta that is unnoticable but needed to make the bar chart elements align
// correctly if a value is 0 element is aligned at axle instead of 'stacked' on chart.
export function addTinyDelta(value: number) {
    if (value === 0) {
        return value + 0.0000000000001;
    }
    return value;
}

function zipIngredientAmount(data: Array<LabelTypeValueTyped>) {
    const result = new Map<string, LabelSugarSalt>();
    if (data) {
        for (const item of data) {
            if (!result.has(item.label)) {
                result.set(item.label, { label: item.label, Salt: 0, Sugar: 0 });
            }
            const obj = result.get(item.label);
            if (obj) {
                if (item.type === "Salt") {
                    result.set(item.label, { ...obj, Salt: item.value });
                }
                if (item.type === "Sugar") {
                    result.set(item.label, { ...obj, Sugar: item.value });
                }
            }
        }
        let values = Array.from(result.values());
        return values.sort((a, b) => (a.Sugar ?? 0) - (b.Sugar ?? 0));
    }
    return null;
}

export function zipBaselineStatusQuoPrice(data: Array<LabelTypeValueTyped>, selectedNutrient: NutrientType) {
    const result = new Map<string, LabelNoTaxSugarTaxSaltTax>();
    if (data) {
        for (const item of data) {
            if (!result.has(item.label)) {
                result.set(item.label, {
                    label: item.label,
                    "No Tax": 0,
                    "Sugar Tax": 0,
                    "Salt Tax": 0,
                    increase_percent: 0,
                    increase_absolute: 0,
                });
            }
            const obj = result.get(item.label);
            if (obj) {
                if (selectedNutrient === "sugar") {
                    if (item.type === "base_existing Sugar tax") {
                        result.set(item.label, { ...obj, "No Tax": item.value });
                    }
                    if (item.type === "total_existing Sugar tax") {
                        result.set(item.label, { ...obj, "Sugar Tax": item.value });
                    }
                } else if (selectedNutrient === "salt") {
                    if (item.type === "base_existing Salt tax") {
                        result.set(item.label, { ...obj, "No Tax": item.value });
                    }
                    if (item.type === "total_existing Salt tax") {
                        result.set(item.label, { ...obj, "Salt Tax": item.value });
                    }
                }
            }
        }
        let values = Array.from(result.values());

        values = values.map((categoryData) => {
            const noTax = categoryData["No Tax"];
            const sugarTax = categoryData["Sugar Tax"];
            const saltTax = categoryData["Salt Tax"];
            const combinedTax = sugarTax + saltTax;

            const canCalculate = noTax !== 0;
            categoryData.increase_percent = canCalculate ? combinedTax / noTax : 0;
            categoryData.increase_absolute = combinedTax - noTax;
            categoryData["Salt Tax"] = addTinyDelta(saltTax);
            return categoryData;
        });

        // return values.sort((a, b) => (a['Sugar Tax'] ?? 0) - (b['Sugar Tax'] ?? 0) + (a['Salt Tax'] ?? 0) - (b['Salt Tax'] ?? 0))
        return values;
    }
    return null;
}

export function zipBaselineNewLevyPrice(data: Array<LabelTypeValueTyped>, selectedNutrient: NutrientType) {
    const result = new Map<string, LabelNoTaxSugarTaxSaltTax>();
    if (data) {
        for (const item of data) {
            if (!result.has(item.label)) {
                result.set(item.label, {
                    label: item.label,
                    "No Tax": 0,
                    "Sugar Tax": 0,
                    "Salt Tax": 0,
                    increase_percent: 0,
                    increase_absolute: 0,
                });
            }
            const obj = result.get(item.label);
            if (obj) {
                if (selectedNutrient === "sugar") {
                    if (item.type === "base_excise Sugar tax") {
                        result.set(item.label, { ...obj, "No Tax": item.value });
                    }
                    if (item.type === "total_excise Sugar tax") {
                        result.set(item.label, { ...obj, "Sugar Tax": item.value });
                    }
                } else if (selectedNutrient === "salt") {
                    if (item.type === "base_excise Salt tax") {
                        result.set(item.label, { ...obj, "No Tax": item.value });
                    }
                    if (item.type === "total_excise Salt tax") {
                        result.set(item.label, { ...obj, "Salt Tax": item.value });
                    }
                }
            }
        }
        let values = Array.from(result.values());

        values = values.map((categoryData) => {
            const noTax = categoryData["No Tax"];
            const sugarTax = categoryData["Sugar Tax"];
            const saltTax = categoryData["Salt Tax"];
            const combinedTax = sugarTax + saltTax;

            const canCalculate = noTax !== 0;
            categoryData.increase_percent = canCalculate ? combinedTax / noTax : 0;
            categoryData.increase_absolute = combinedTax - noTax;
            categoryData["Salt Tax"] = addTinyDelta(saltTax);
            return categoryData;
        });

        // return values.sort((a, b) => (a['Sugar Tax'] ?? 0) - (b['Sugar Tax'] ?? 0) + (a['Salt Tax'] ?? 0) - (b['Salt Tax'] ?? 0))
        return values;
    }
    return null;
}

function zipAveragePriceChange(data: Array<LabelTaxTypeValueTyped>, selectedNutrient: NutrientType) {
    const result = {};
    if (data) {
        for (const item of data) {
            if (!result[item.label]) {
                result[item.label] = {
                    label: item.label[0].toUpperCase() + item.label.slice(1),
                };
            }

            if (selectedNutrient === "sugar") {
                if (item.type === "new_Sugar tax") {
                    result[item.label]["Sugar Tax"] = item.value;
                }
            } else if (selectedNutrient === "salt") {
                if (item.type === "new_Salt tax") {
                    result[item.label]["Salt Tax"] = item.value;
                }
            }
        }
        return Object.values(result);
    }
    return null;
}

// weird flipped type vs label?
function zipVolumeChange(data: Array<NameTaxTypeValueTyped>, selectedNutrient: NutrientType) {
    const result = new Map<string, LabelSaltTaxSugarTax>();
    if (data) {
        for (const item of data) {
            if (!result.has(item.taxType)) {
                result.set(item.taxType, {
                    category: item.taxType,
                    "Salt Tax": 0,
                    "Sugar Tax": 0,
                });
            }
            const obj = result.get(item.taxType);
            if (obj) {
                if (selectedNutrient === "sugar") {
                    if (item.name === "Sugar tax") {
                        result.set(item.taxType, { ...obj, "Sugar Tax": item.value });
                    }
                } else if (selectedNutrient === "salt") {
                    if (item.name === "Salt tax") {
                        result.set(item.taxType, { ...obj, "Salt Tax": item.value });
                    }
                }
            }
        }
        let values = Array.from(result.values());
        return values.sort((a, b) => (a["Sugar Tax"] ?? 0) - (b["Sugar Tax"] ?? 0) + (a["Salt Tax"] ?? 0) - (b["Salt Tax"] ?? 0));
    }
    return null;
}

// again weird flipped type vs label?
function zipAverageChangeGroup(data: Array<NameTaxTypeValueTyped>, selectedNutrient: NutrientType) {
    const result = {};
    if (data) {
        for (const item of data) {
            if (!result[item.taxType]) {
                result[item.taxType] = {
                    name: item.taxType[0].toUpperCase() + item.taxType.slice(1),
                };
            }
            if (selectedNutrient === "sugar") {
                if (item.name === "Sugar tax") {
                    result[item.taxType]["Sugar Tax"] = item.value;
                }
            } else if (selectedNutrient === "salt") {
                if (item.name === "Salt tax") {
                    result[item.taxType]["Salt Tax"] = item.value;
                }
            }
        }
        return Object.values(result);
    }
    return null;
}

export function zipTaxRevenue(data: Array<NameTaxTypeValueTyped>, selectedNutrient: NutrientType) {
    const result = new Map<string, LabelVatExciseTaxChange>();
    const dummy: LabelVatExciseTaxChange = {
        label: "",
        VAT: 0,
        "Excise Tax Sugar": 0,
        "Excise Tax Salt": 0,
        total: 0,
        increase_absolute: 0,
        increase_percent: 0,
    };

    result.set("Status quo", { ...dummy, label: "Status quo" });
    result.set("New levy", { ...dummy, label: "New levy" });

    if (data) {
        for (const item of data) {
            const obj = result.get(item.name);
            if (obj) {
                if (selectedNutrient === "sugar") {
                    if (item.taxType === "VAT_Sugar") {
                        result.set(item.name, { ...obj, VAT: item.value });
                    }

                    if (item.taxType === "excise tax_Sugar") {
                        result.set(item.name, { ...obj, "Excise Tax Sugar": item.value });
                    }
                } else if (selectedNutrient === "salt") {
                    if (item.taxType === "VAT_Salt") {
                        result.set(item.name, { ...obj, VAT: item.value });
                    }
                    if (item.taxType === "excise tax_Salt") {
                        result.set(item.name, { ...obj, "Excise Tax Salt": item.value });
                    }
                }
            }
        }
        // update total and increase for all items
        let no = result.get("Status quo");
        let all = result.get("New levy");

        if (no) {
            no = { ...no, total: no.VAT };
        }

        if (no && all) {
            const prevTotal = no.VAT + no["Excise Tax Sugar"] + no["Excise Tax Salt"];
            const currentTotal = all.VAT + all["Excise Tax Sugar"] + all["Excise Tax Salt"];
            const increase = currentTotal - prevTotal;
            all = {
                ...all,
                total: currentTotal,
                increase_absolute: increase,
                increase_percent: (increase / prevTotal) * 100,
            };
        }

        // add tiny delta only after doing other calculations
        if (no) {
            result.set("Status quo", {
                ...no,
                "Excise Tax Salt": addTinyDelta(no["Excise Tax Salt"]),
            });
        }

        if (all) {
            result.set("New levy", {
                ...all,
                "Excise Tax Salt": addTinyDelta(all["Excise Tax Salt"]),
            });
        }

        return Array.from(result.values());
    }
    return null;
}
