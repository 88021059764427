import { FormControlLabel, RadioGroup } from '@mui/material'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { NutrientType } from '../../../api/modelBObjects';
import { FormBContext } from '../../../context/FormBContext';
import { CcRadio } from '../../Inputs/CcRadio'

function SelectNutientsRadioGroup() {
    const { t } = useTranslation();
    const {
        selectedNutrient,
        setSelectedNutrient,
    } = useContext(FormBContext);

    const handleSelectedNutrientChange = (e, v) => {
        setSelectedNutrient(v);
    }

    return (
        <>
            <div className='radioGroupContainer'>
                <RadioGroup
                    value={selectedNutrient}
                    onChange={handleSelectedNutrientChange}>
                    <FormControlLabel label={t("sideBar.sugar")}
                        control={<CcRadio />}
                        value={NutrientType.Sugar} />
                    <FormControlLabel label={t("sideBar.salt")}
                        control={<CcRadio />}
                        value={NutrientType.Salt} />
                </RadioGroup>
            </div>
        </>
    )
}

export default SelectNutientsRadioGroup