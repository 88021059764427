import { BarItem, BarItemProps, BarDatum } from "@nivo/bar";
import { useTheme } from "@nivo/core";
import React, { useContext } from "react";
import { redColorWithOpacity } from "../../components/ChartColors";
import Tooltip from "../../components/Tooltip";
import { FormAContext } from "../../context/FormAContext";
import BarChart from "../../widgets/BarChart";
import { formatNoDecimals } from "../../widgets/FormatNumber";

const BarWithTotals = (props: BarItemProps<BarDatum>) => {
    const theme = useTheme();

    if (props.bar.data.id === "Regular") {
        // Last key
        const bar = props.bar;
        const total = props.bar.data.data.total;
        const transform = `translate(${bar.absX + bar.width - 30}, ${
            bar.absY + bar.height / 2
          })`;
        return (
            <>
                <BarItem {...props} />
                <g transform={transform}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{
                            ...theme.labels.text,
                            pointerEvents: "none",
                        }}
                    >
                        {total}
                    </text>
                </g>
            </>
        );
    } else {
        return <BarItem {...props} />;
    }
};

export function CoreChart() {
    const { results } = useContext(FormAContext);
    if (results) {
        const effect = results.total_effect_on_volume;
        const data = [
            {
                tax: "Pre-tax",
                "No-Calorie": effect.no_cal_pretax,
                "Low-Calorie": effect.low_cal_pretax,
                Regular: effect.high_cal_pretax,
                total: formatNoDecimals(effect.no_cal_pretax + effect.low_cal_pretax + effect.high_cal_pretax),
            },
            {
                tax: "Post-tax",
                "No-Calorie": effect.no_cal_posttax,
                "Low-Calorie": effect.low_cal_posttax,
                Regular: effect.high_cal_posttax,
                total: formatNoDecimals(effect.no_cal_posttax + effect.low_cal_posttax + effect.high_cal_posttax),
            },
        ];

        return (
            <BarChart
                data={data}
                keys={["No-Calorie", "Low-Calorie", "Regular"]}
                axisMarginHigh
                minValue={"auto"}
                label={(d) => `${formatNoDecimals(d.value)}`}
                indexBy="tax"
                margin={{ top: 0, right: 60, bottom: 0, left: 70 }}
                padding={0.25}
                layout="horizontal"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={redColorWithOpacity}
                colorBy="id"
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                axisBottom={null}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor="white"
                enableGridX={false}
                enableGridY={false}
                theme={{
                    fontSize: 16,
                    textColor: "#474747",
                }}
                // @ts-ignore
                barComponent={BarWithTotals}
                // barComponent={CustomBarComponent}
                tooltip={(e) => (
                    <Tooltip>
                        {e.id ? `${e.id}-${e.indexValue} ` : ""}
                        {e.value ? formatNoDecimals(e.value) : "No data"}
                    </Tooltip>
                )}
            />
        );
    } else {
        return <div> No data </div>;
    }
}
export const ChangeInVolumeChart = () => {
    return (
        <div style={{ width: "100%", position: "relative", display: "flex", flexDirection: "column", gap: "24px" }}>
            <div style={{ height: "109px" }}>
                <CoreChart />
            </div>
            <div className="legend">
                <div className="rect redRect"></div> <span>No Calorie</span>
                <div className="rect redRectOpa60"></div>
                <span>Low Calorie</span>
                <div className="rect redRectOpa30"></div>
                <span>Regular</span>
            </div>
        </div>
    );
};
