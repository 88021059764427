import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./ModelBTaxesGroup.scss";
import { Box, Button, Fade, Stack, Typography } from "@mui/material";
import { LevyTier, NutrientType, FrontEndLevies } from "../../../api/modelBObjects";
import { TaxTier } from "./TaxTier";
import { Add } from "@mui/icons-material";

type Props = {
    taxes: FrontEndLevies,
    setTaxes: (tieredTax: FrontEndLevies) => void,
    nutrientType: NutrientType,
}
const sortTiers = (taxes: FrontEndLevies): Array<{ key: number, tier: LevyTier }> => {
    return [...taxes.tiers.entries()].sort((a: [number, LevyTier], b: [number, LevyTier]) => {
        const tdif = (a[1].threshold ?? 0) - (b[1].threshold ?? 0);
        if (tdif !== 0) { return tdif } // default to sorting by threshold
        return (a[0]) - (b[0]); // use key otherwise
    }).map(v => ({ key: v[0], tier: v[1] }));
};

export const TaxesGroup = (props: Props) => {
    const { taxes, setTaxes } = props;
    const { t } = useTranslation();

    const [sortedTiers, setSortedTiers] = useState(sortTiers(taxes));

    const handleBaseChange = (rate: number | null): void => {
        setTaxes({ ...taxes, base: rate });
    }

    const handleRateChange = (tierIndex: number, rate: number | null): void => {
        const newTier = { rate: rate, threshold: taxes.tiers.get(tierIndex)?.threshold ?? null };
        taxes.tiers.set(tierIndex, newTier);
        setTaxes({ ...taxes });
    }

    const handleThresholdChange = (tierIndex: number, threshold: number | null): void => {
        const newTier = { threshold: threshold, rate: taxes.tiers.get(tierIndex)?.rate ?? null };
        taxes.tiers.set(tierIndex, newTier);
        setTaxes({ ...taxes });
    }

    const handleRemoveBase = (): void => {
        taxes.base = taxes.tiers.get(0)?.rate ?? 0;
        taxes.tiers.delete(0);
        setTaxes({ ...taxes });
    }

    const handleRemoveTier = (tierKey: number): void => {
        taxes.tiers.delete(tierKey);
        setTaxes({ ...taxes })
    }

    const handleAddTier = (): void => {
        const lastTier = sortedTiers.length > 0 ?
            taxes.tiers.get(sortedTiers[sortedTiers.length - 1]?.key) : // use previous tier
            { threshold: 0, rate: taxes.base }; // use base with threshold 0
        taxes.tiers.set(Math.max(...taxes.tiers.keys(), 0) + 1, { threshold: (lastTier?.threshold ?? 0) + 1, rate: lastTier?.rate ?? 0 });
        setTaxes({ ...taxes })
    }

    useEffect(() => {
        setSortedTiers(sortTiers(taxes));
    }, [taxes]);

    return <Fade in={true} timeout={{ enter: 1000, exit: 500 }}>
        <div className="modelBTaxesGroupComponent">
            <Stack gap='8px'>
                <Stack direction="row" spacing="3em">
                    <Typography>{taxes.tiers.size === 0 ? t(`modelB.taxesFixed`) : t('modelB.taxesVariable')}</Typography>
                    { // render flat tax if there are no tiers
                        taxes.tiers.size === 0 &&
                        <TaxTier rate={taxes.base} rateChanged={handleBaseChange} removeable={false} />
                    }
                </Stack>
                <Stack maxHeight="165px" overflow="auto">
                    { // render tiers if there is at least 1
                        taxes.tiers.size > 0 &&
                        <>
                            {/* first tier is always ' < x = baserate '*/}
                            <TaxTier
                                rate={taxes.base}
                                rateChanged={handleBaseChange}
                                upperThreshold={sortedTiers[0]?.tier.threshold}
                                onRemove={() => handleRemoveBase()}
                            />
                            {/* all intermediate tiers ' x - y = rate '*/}
                            {sortedTiers.map(({ key, tier }, index: number) => {
                                if (key !== sortedTiers[sortedTiers.length - 1].key) {
                                    return <TaxTier
                                        key={key}
                                        rate={tier.rate}
                                        rateChanged={(e) => handleRateChange(key, e)}
                                        lowerThreshold={tier.threshold}
                                        lowerThresholdChanged={(e) => handleThresholdChange(key, e)}
                                        upperThreshold={sortedTiers[index + 1].tier.threshold}
                                        onRemove={() => handleRemoveTier(key)}
                                    />
                                }
                                else {
                                    /* last tier is always ' > x = rate '*/
                                    return <TaxTier
                                        key={key}
                                        rate={sortedTiers[sortedTiers.length - 1].tier.rate ?? null}
                                        rateChanged={(e) => handleRateChange(sortedTiers[sortedTiers.length - 1].key, e)}
                                        lowerThreshold={sortedTiers[sortedTiers.length - 1].tier.threshold ?? null}
                                        lowerThresholdChanged={(e) => handleThresholdChange(sortedTiers[sortedTiers.length - 1].key, e)}
                                        onRemove={() => handleRemoveTier(sortedTiers[sortedTiers.length - 1].key)}
                                    />
                                }
                            })}
                        </>
                    }
                </Stack>
            </Stack>
                <Button variant="contained" onClick={handleAddTier} sx={{
                    color: '#fff',
                    background: '#F40009',
                    borderRadius: '4px',
                    mt: '24px',
                    width:'100%'

                }}>
                    <Stack direction="row" spacing="1em">
                        <Add />
                        <Box>
                            {t(`modelB.addTier`)}
                        </Box>
                    </Stack>
                </Button>
        </div>
    </Fade>
};
