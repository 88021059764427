import React from 'react';

export default function Tooltip(props: { children: React.ReactNode }) {
    return <div style={{
        background: "white",
        padding: "9px 12px",
        border: "1px solid #ccc",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        color: "black"
    }}>
        {props.children}
    </div >
}
