export type CalorieValues = {
    NOCALORIES: number,
    LOWCALORIES: number,
    REGULAR: number,
}

export type ModelAType = {
    country: string,
    tax_rates: CalorieValues,
    portfolio_split: CalorieValues,
    fc_elasticity: number,
    ic_elasticity: number,
}

export const defaultsA: ModelAType = {
    country: "",
    tax_rates: {
        NOCALORIES: 0,
        LOWCALORIES: 0,
        REGULAR: 0,
    },
    portfolio_split: {
        NOCALORIES: 0,
        LOWCALORIES: 0,
        REGULAR: 0,
    },
    fc_elasticity: 0,
    ic_elasticity: 0,
}