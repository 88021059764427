import React, { useContext } from "react";
import { BarDatum, BarItem, BarItemProps, BarTooltipProps } from "@nivo/bar";
import {
  FormBContext,
  LabelNoTaxSugarTaxSaltTax,
} from "../../context/FormBContext";
import { useTranslation } from "react-i18next";
import { useTheme } from "@nivo/core";
import BarChart from "../../widgets/BarChart";
import Tooltip from "../../components/Tooltip";
import { NonZeroToDisplayString } from "../../utils/DisplayString";
import { NutrientType } from "../../api/modelBObjects";
type Props = {
  isNewLevy: boolean;
};
const BarWithTotals = (props: BarItemProps<BarDatum>) => {
  const theme = useTheme();

  if (
    (props.bar.data.id === "Salt Tax" && props.bar.data.value >= 0) ||
    //@ts-ignore
    (props.bar.data.id === "No Tax" && props.bar.data.data["Salt Tax"] <= 0)
  ) {
    // Last key
    const bar = props.bar;
    const transform = `translate(${bar.absX + bar.width - 200}, ${
      bar.absY + bar.height - 35
    })`;
    const total = props.bar.data.data.total;
    
    return (
      <>
        <BarItem {...props} />
        <g transform={transform}>
          <text
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              ...theme.labels.text,
              pointerEvents: "none",
            }}
          >
            {total}
          </text>
        </g>
      </>
    );
  } else {
    return <BarItem {...props} />;
  }
};

function CoreChart({ isNewLevy , selectedNutrient }: Props & {selectedNutrient: string}) {
  const { t } = useTranslation();
  const { results } = useContext(FormBContext);
  if (
    results &&
    results.baseLinePricesStatusQuoData &&
    results.baseLinePricesNewLevyData
  ) {
    let baseLinePricesData;
    if (isNewLevy) {
      baseLinePricesData = results.baseLinePricesNewLevyData.map((d) => {
        const total = (Number(d["No Tax"].toFixed(2)) + Number(d[`${selectedNutrient[0].toUpperCase() + selectedNutrient?.substring(1)} Tax`].toFixed(2))).toFixed(2)
        return {
          ...d,
          label: t("modelB.categories." + d.label),
          total,
        };
      });
    } else {
      baseLinePricesData = results.baseLinePricesStatusQuoData.map((d) => {
        const total = (Number(d["No Tax"].toFixed(2)) + Number(d[`${selectedNutrient[0].toUpperCase() + selectedNutrient?.substring(1)} Tax`].toFixed(2))).toFixed(2)
        console.log(typeof d["No Tax"].toFixed(2) , Number(d["No Tax"].toFixed(2)) + Number(d[`${selectedNutrient[0].toUpperCase() + selectedNutrient?.substring(1)} Tax`].toFixed(2)))
        return {
          ...d,
          label: t("modelB.categories." + d.label),
          total,
        };
      });
    }

    return (
      <BarChart
        data={baseLinePricesData}
        keys={["No Tax", "Sugar Tax", "Salt Tax"]}
        indexBy="label"
        margin={{ top: 20, right: 80, bottom: 50, left: 230 }}
        padding={0.2}
        layout="horizontal"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={['#F40009','#000','#000']}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        enableGridX={false}
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={"white"}
        label={(d) => NonZeroToDisplayString(d.value)}
        theme={{
          fontSize:16,
          textColor:'#474747',
      }}
        barComponent={BarWithTotals}
        tooltip={(e: BarTooltipProps<LabelNoTaxSugarTaxSaltTax>) => (
          <Tooltip>
            {e.id ? `${e.id} - ${e.indexValue} ` : ""}
            {e.value ? e.value.toFixed(2) : "No data"}
            <div>{`Increase in percentage (${(
              (e.data.increase_percent || 0) * 100
            ).toFixed(1)}%)`}            
            </div>
          </Tooltip>
        )}
      />
    );
  } else {
    return <div> No data </div>;
  }
}
export const BaselinePricesAndIncreasesChart = ({ isNewLevy }: Props) => {
  const { selectedNutrient } = useContext(FormBContext);
  console.log(selectedNutrient)
  return (
    <div style={{ width: "100%" , position: "relative" , display:'flex' , flexDirection: 'column' , gap:'24px'  }}>
      <div style={{ height: "565px" }}> 

      <CoreChart isNewLevy={isNewLevy} selectedNutrient={selectedNutrient} />
      </div>
      <div className="legend">
        <div className="rect redRect"></div>
        <span>No Tax</span>
        {selectedNutrient === NutrientType.Sugar && (
          <>
            <div className="rect blackRect"></div> <span>Sugar Tax</span>
          </>
        )}
        {selectedNutrient === NutrientType.Salt && (
          <>
            <div className="rect blackRect"></div> <span>Salt Tax</span>
          </>
        )}
      </div>
    </div>
  );
};
