import { Box, FormControlLabel, RadioGroup } from '@mui/material'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { FormBContext } from '../../../context/FormBContext';
import { CcRadio } from '../../Inputs/CcRadio'

function ElasticityRadioGroup() {
    const { t } = useTranslation();
    const { overrideElasticity, setOverrideElasticity } = useContext(FormBContext);

    const controlProps = () => ({
        name: "color-radio-button-demo",
        inputProps: { "aria-label": "true" },
    });

    return (
        <>
            <Box className='elasticity radioGroupContainer' display='flex' justifyContent='space-between' alignItems="center" mb='8px'>
                <p>{t('sideBar.modelBElasticityRadioTitle')}</p>
                <RadioGroup sx={{width:'20%'}}>
                    <FormControlLabel label={t("sideBar.elasticityRadio.yes")}
                        control={<CcRadio {...controlProps()}
                            checked={!overrideElasticity}
                            value={!overrideElasticity}
                            onChange={() => setOverrideElasticity(false)} />}
                    />
                    <FormControlLabel label={t("sideBar.elasticityRadio.no")}
                        control={<CcRadio {...controlProps()}
                            checked={overrideElasticity}
                            value={overrideElasticity}
                            onChange={() => setOverrideElasticity(true)} />}
                    />
                </RadioGroup>
            </Box>
        </>
    )
}

export default ElasticityRadioGroup