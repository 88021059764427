import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './assets/languages/en.json';

i18next
    .use(initReactI18next)
    .init(
        {
            debug: true,
            fallbackLng: 'en',
            // lng: 'cimode',
            resources: {
                en
            }
        }
    );