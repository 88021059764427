import React, { useContext } from 'react';
import { orangeColor } from '../../components/ChartColors';
import { FormBContext } from '../../context/FormBContext';
import { useTranslation } from 'react-i18next';
import BarChart from '../../widgets/BarChart';
import Tooltip from '../../components/Tooltip';
import { NonZeroToPercentString } from '../../utils/DisplayString';
import { NutrientType } from '../../api/modelBObjects';
import { BarDatum, BarItem, BarItemProps } from '@nivo/bar';


const CustomBarComponent = (props: BarItemProps<BarDatum>) => {
    const bar = props.bar;

    const { x, y, width, height, data } = bar



    return <>
        <BarItem {...props} />
        <g>
            {data.value && <text
                x={x + width / 2}
                y={y + height + 10} // the important bit!!
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: 12,
                    pointerEvents: 'none',
                    fill: '#000',
                    opacity: 0.75,
                }}
            >
                {`${data.value.toFixed(2)} %`}
            </text>}
        </g>
    </>
}


function CoreChart() {
    const { t } = useTranslation();
    const { results } = useContext(FormBContext)
    if (results && results.volumeChangeData) {
        const volumeChangeData = results.volumeChangeData
            .map(d => {
                return {
                    ...d,
                    category: t("modelB.categories." + d.category)
                }
            });

        return <BarChart
            data={volumeChangeData}
            keys={['Sugar Tax', 'Salt Tax']}
            indexBy="category"
            margin={{ top: 220, right: 70, bottom: 40, left: 70 }}
            padding={0.1}
            groupMode="stacked"
            axisMarginLow
            valueScale={{ type: 'linear', }}
            indexScale={{ type: 'band', round: false }}
            colors={[orangeColor, orangeColor]}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisBottom={null}
            axisRight={null}
            axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                legendPosition: 'middle',
                legendOffset: 32,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: -40,
                format: value => `${Number(value)} %`,
            }}
            enableGridX={false}
            enableGridY={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={'white'}
            label={d => NonZeroToPercentString(d.value)}
            enableLabel={false}

            theme={{
                fontSize: 16,
                textColor: '#474747',
            }}

            barComponent={CustomBarComponent}

            tooltip={e =>
                <Tooltip>
                    {e.id ? `${e.id} - ${e.indexValue}: ` : ""}
                    {e.value
                        ? e.value.toFixed(2) + "%"
                        : "No data"}
                </Tooltip>
            }

        />
    }
    else {
        return <div> No data </div>;
    }
}

export const VolumeChartPerCategoryChart = () => {
    const { selectedNutrient } = useContext(FormBContext)
    return <div style={{ height: "465px", width: "100%" }}>
        <CoreChart />
        <div className='legend'>
            {selectedNutrient === NutrientType.Sugar && <><div className='rect orangeRect'></div> <span>Sugar Tax</span></>}
            {selectedNutrient === NutrientType.Salt && <><div className='rect orangeRect'></div><span>Salt Tax</span></>}
        </div>
    </div>
}
