import { Divider, Paper, Stack, Tab, Tabs, Typography, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormAContext } from "../../../context/FormAContext";
import { ChangeInConsumptionChart } from "../../../charts/ModelA/ChangeInConsumptionChart";
import { ChangeInIncomeChart } from "../../../charts/ModelA/ChangeInIncomeChart";
import { ChangeInPriceChart } from "../../../charts/ModelA/ChangeInPriceChart";
import { ChangeInVolumeChart } from "../../../charts/ModelA/ChangeInVolumeChart";
import { ResetButtonA } from "../../Buttons/ResetButtonA";
import { HandleChart } from "../../../widgets/ExportChartsForPdfModelA";
import { ExportChartButton } from "../../../widgets/ExportChartButton";

const tabs = [{
  label: "Effect on price, volume & consumption",
  value: 0
}, {
  label: "Effect on income & employment",
  value: 1
}]



function EmploymentData() {
  const { results } = useContext(FormAContext);
  if (results) {
    return (
      <Stack alignItems='center' gap='8px'>
        <svg width="164" height="164" viewBox="0 0 164 164" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M69.1397 109.771C84.8085 106.156 99.3977 102.985 92.332 89.6467C70.8343 49.043 86.633 27.3335 109.333 27.3335C132.485 27.3335 147.901 49.8767 126.335 89.6467C119.057 103.067 134.186 106.231 149.527 109.771C163.098 112.907 164 119.542 164 131.029L163.98 136.667H54.6872C54.6872 121.661 53.4845 113.386 69.1397 109.771ZM0.0136667 136.667H41.0205C40.8292 91.9632 61.4863 111.404 61.4863 75.8433C61.4863 62.1152 52.5278 54.6668 41 54.6668C23.9713 54.6668 12.1223 70.9507 28.249 101.4C33.5448 111.404 22.6115 113.782 10.8582 116.495C0.6765 118.845 0 123.82 0 132.437L0.0136667 136.667Z" fill="#F40009" />
        </svg>
        <Typography sx={{
          fontSize: '40px',
          fontWeight: '400',
          lineHeight: '48px',
          color: '#F40009'

        }}>{results.employment_effect.jobs.toFixed(0)}</Typography>
        <Typography sx={{
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '19px',
          color: '#474747'
        }}>Jobs</Typography>
      </Stack>
    );
  } else {
    return <p className="rangeBarEmployment">No data</p>;
  }
}

export const ChartsContainer = (props) => {
  const { getPdfData } = useContext(FormAContext);
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { t } = useTranslation();
  const Item = styled(Stack)(({ theme }) => ({
    textAlign: "center",
    alignItems: "center",
    padding: '8px',
    width: '100%'
  }));

  return (
    <div className="modelAChartContainer">
      {props.isCalculated && (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>

          <Stack gap='24px' width="90%" alignItems='center' mt='55px' mx='auto'>

            <Box sx={{
              width: '666px', borderRadius: "5px", border: "1px solid #797979", boxShadow: '0px 4px 4px 0px #00000040'
            }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                {tabs.map(({ label, value }) => (<Tab key={value} value={value} label={label} sx={{ flex: "1", fontSize: "16px", padding: '9px 16px', textTransform: 'none' }} />))}
              </Tabs>
            </Box>
            <Paper variant='outlined' sx={{
              width: '100%',
              padding: '24px',
              borderRadius: '12px',
              border: '1px solid #797979',
              boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2)',
              p: "24px",
              m: "auto",
              display: 'flex',
              flexDirection: 'column'
            }}>
              {value === 0 ? (
                <Grid
                  container
                  rowSpacing={0.5}
                >
                  <Grid item xs={4.8}>
                    <Item
                      className="chartContainer"
                    >
                      <Box id="changeInPriceChart" height='100%'>
                        <ExportChartButton
                          handleExport={() =>
                            HandleChart(getPdfData(), "changeInPriceChart")
                          }
                        />
                        <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                          <Typography sx={{
                            fontSize: '20px',
                            fontWeight: '600',
                            lineHeight: '24px',
                            color: '#262626'

                          }}>Effect on beverage price</Typography>

                          <Box sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: '#FF1D02'
                          }} />
                          <Typography sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '19px',
                            color: '#262626'
                          }}>
                            Increase in % versus average
                          </Typography>

                        </Box>
                        <ChangeInPriceChart></ChangeInPriceChart>
                      </Box>
                    </Item>
                  </Grid>

                  <Grid item xs={0.2} display='flex' justifyContent='center'>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item xs={7}>
                    <Item
                      className="chartContainer"
                    >
                      <Box id="changeInVolumeChart" width="100%" height='100%' pb="45px">
                        <ExportChartButton
                          handleExport={() =>
                            HandleChart(getPdfData(), "changeInVolumeChart")
                          }
                        />
                        <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                          <Typography sx={{
                            fontSize: '20px',
                            fontWeight: '600',
                            lineHeight: '24px',
                            color: '#262626'
                          }}>Effect on volume</Typography>
                          <Box sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: '#FF1D02'
                          }} />
                          <Typography sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '19px',
                            color: '#262626'
                          }}>
                            in million litres
                          </Typography>
                        </Box>
                        <ChangeInVolumeChart />
                      </Box>
                    </Item>
                    <Divider />
                    <Item
                      className="chartContainer"
                    >
                      <Box id="changeInConsumptionChart" width="100%" height='100% ' pb="45px" mt='24px'>
                        <ExportChartButton
                          handleExport={() =>
                            HandleChart(
                              getPdfData(),
                              "changeInConsumptionChart"
                            )
                          }
                        />
                        <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                          <Typography sx={{
                            fontSize: '20px',
                            fontWeight: '600',
                            lineHeight: '24px',
                            color: '#262626'

                          }}>Effect on consumption value</Typography>

                          <Box sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: '#FF1D02'
                          }} />
                          <Typography sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '19px',
                            color: '#262626'
                          }}>
                            in million EUR
                          </Typography>
                        </Box>
                        <ChangeInConsumptionChart />
                      </Box>
                    </Item>
                  </Grid>
                </Grid>
              ) : (
                <Grid container
                  width='100%'>
                  <Grid item xs={5.9}>
                    <Item
                      className="chartContainer"
                    >
                      <Box id="changeInIncomeChart" width='100%' height='100%'>
                        <ExportChartButton
                          handleExport={() =>
                            HandleChart(getPdfData(), "changeInIncomeChart")
                          }
                        />
                        <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                          <Typography sx={{
                            fontSize: '20px',
                            fontWeight: '600',
                            lineHeight: '24px',
                            color: '#262626'

                          }}>Effect on incomes</Typography>

                          <Box sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: '#FF1D02'
                          }} />
                          <Typography sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '19px',
                            color: '#262626'
                          }}>
                            in million EUR
                          </Typography>
                        </Box>
                        <ChangeInIncomeChart />
                      </Box>
                    </Item>
                  </Grid>

                  <Grid item xs={0.2} display='flex' justifyContent='center'>
                    <Divider orientation="vertical" />
                  </Grid>

                  <Grid item xs={5.9}>
                    <Item
                      className="chartContainer rangeBar"
                    >
                      <Box id="employmentData" width='100%' height='100%'>
                        <ExportChartButton
                          handleExport={() =>
                            HandleChart(getPdfData(), "employmentData")
                          }
                        />
                        <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                          <Typography sx={{
                            fontSize: '20px',
                            fontWeight: '600',
                            lineHeight: '24px',
                            color: '#262626'

                          }}>Employment effect</Typography>

                          <Box sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: '#FF1D02'

                          }} />

                          <Typography sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '19px',
                            color: '#262626'
                          }}>
                            in number of jobs
                          </Typography>

                        </Box>
                        <EmploymentData />
                      </Box>
                    </Item>
                  </Grid>
                </Grid>
              )}

              <ResetButtonA
                setIsCalculated={props.setIsCalculated}
                text={t("sideBar.resetButton")}
                className="reset"
              />
            </Paper>
          </Stack>
        </Box>
      )}
    </div>
  );
};
