import React, { useContext } from "react";
import { NutrientType } from "../../api/modelBObjects";
import { orangeColor } from "../../components/ChartColors";
import Tooltip from "../../components/Tooltip";
import { FormBContext } from "../../context/FormBContext";
import { NonZeroToPercentString } from "../../utils/DisplayString";
import BarChart from "../../widgets/BarChart";
import { BarDatum, BarItem, BarItemProps } from '@nivo/bar';


const CustomBarComponent = (props: BarItemProps<BarDatum>) => {

    const bar = props.bar;

    const { x , y, width , height , data} = bar



    return <>
            <BarItem {...props} />
            <g>

            <text
                x={x + width / 2}
                y={y + height + 10} // the important bit!!
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: 12,
                    pointerEvents: "none",
                    fill: "#000",
                    opacity: 0.75,
                }}
            >
                {data.indexValue}
            </text>
            </g>
        </>
}

function CoreChart() {
    const { results } = useContext(FormBContext);
    if (results && results.averageChangeGroupData) {
        const averageChangeGroupData = results.averageChangeGroupData;
        return (
            <BarChart
                // @ts-ignore
                data={averageChangeGroupData}
                keys={["Sugar Tax", "Salt Tax"]}
                indexBy="name"
                margin={{ top: 10, right: 50, bottom: 60, left: 60 }}
                axisMarginLow
                padding={0.3}
                groupMode="stacked"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: false }}
                colors={[orangeColor, orangeColor]}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: "middle",
                    legendOffset: 0,
                    format: (value) => `${Number(value)} %`,
                }}
                enableGridX={false}
                enableGridY={false}
                labelTextColor={"white"}
                label={(d) => NonZeroToPercentString(d.value)}
                theme={{
                    fontSize: 16,
                    textColor: "#474747",
                }}
                barComponent={CustomBarComponent}
                tooltip={(e) => (
                    <Tooltip>
                        {e.id ? `${e.id} - ${e.indexValue}: ` : ""}
                        {e.value ? `${e.value.toFixed(2)}%` : "No data"}
                    </Tooltip>
                )}
            />
        );
    } else {
        return <div> No data </div>;
    }
}

export const AverageChangePerGroupChart = () => {
    const { selectedNutrient } = useContext(FormBContext);
    return (
        <div style={{ height: "465px", width: "100%" }}>
            <CoreChart />
            <div className="legend">
                {selectedNutrient === NutrientType.Sugar && (
                    <>
                        <div className="rect orangeRect"></div> <span>Sugar Tax</span>
                    </>
                )}
                {selectedNutrient === NutrientType.Salt && (
                    <>
                        <div className="rect orangeRect"></div>
                        <span>Salt Tax</span>
                    </>
                )}
            </div>
        </div>
    );
};
