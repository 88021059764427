import { compile } from 'path-to-regexp';

import config from './config';

type Path = {
    path: string,
    toPath: Function
};

function createPathObject(path: string): Path {
    return {
        path: path,
        toPath: compile(path)
    };
}

export const root = createPathObject(config.baseurl);

export const login = createPathObject(config.baseurl + 'auth/login');
export const loggedin = createPathObject(config.baseurl + 'auth/loggedin');
export const logout = createPathObject(config.baseurl + 'auth/logout');

export const ppt = createPathObject(config.baseurl + 'ppt');
export const bbta = createPathObject(config.baseurl + 'bbta');
export const version = createPathObject(config.baseurl + 'version');

