import Button from '@mui/material/Button';
import React, { useContext } from 'react';
import { FormBContext } from '../../context/FormBContext';
import './CalculateButton.scss';

type Props = {
    text: string,
    className: string,
    setIsCalculated: (isCalculated: boolean) => void,
}

export const ResetButtonB = (props: Props) => {
    const { setIsDisabled } = useContext(FormBContext);

    const { text, className, setIsCalculated } = props;

    return <>
        <Button
            variant='contained'
            className={className}
            onClick={() => { setIsDisabled(false); setIsCalculated(false); }} >{text}</Button>
    </>
};
