import { TableContainer, Table, TableBody, TableRow, TableCell } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export function Version() {
    const { t } = useTranslation();

    return <>
        <h1>{t('version.heading')}</h1>
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell>{t('version.version')}</TableCell>
                        <TableCell >{process.env.REACT_APP_GIT_SHA}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t('version.deployedOn')}</TableCell>
                        <TableCell>{process.env.REACT_APP_DEPLOY_DATE}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </>
}
