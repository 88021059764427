import { LatLngBoundsExpression } from "leaflet";

export const europeCountries = {
    ALB: 1,
    AND: 1,
    AUT: 1,
    BEL: 1,
    BGR: 1,
    BIH: 1,
    BLR: 1,
    CHE: 1,
    CYP: 1,
    CZE: 1,
    DEU: 1,
    DNK: 1,
    ESP: 1,
    EST: 1,
    FIN: 1,
    FRA: 1,
    FRO: 1,
    GBR: 1,
    GIB: 1,
    GRC: 1,
    HRV: 1,
    HUN: 1,
    IMN: 1,
    IRL: 1,
    ISL: 1,
    ITA: 1,
    LIE: 1,
    LTU: 1,
    LUX: 1,
    LVA: 1,
    MCO: 1,
    MDA: 1,
    MKD: 1,
    MLT: 1,
    MNE: 1,
    NLD: 1,
    NOR: 1,
    POL: 1,
    PRT: 1,
    ROU: 1,
    RSB: 1,
    RUS: 1,
    SMR: 1,
    SRB: 1,
    SVK: 1,
    SVN: 1,
    SWE: 1,
    TUR: 1,
    UKR: 1,
    VAT: 1,
    XKX: 1, // Kosovo
};

export const europeBounds: LatLngBoundsExpression = [[40, -10], [60, 10]];


