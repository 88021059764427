import { LatLngBoundsExpression } from "leaflet";

export const africaCountries = {
    BFA: 1,
    DJI: 1,
    BDI: 1,
    BEN: 1,
    BWA: 1,
    ETH: 1,
    NAM: 1,
    SWZ: 1,
    NER: 1,
    LSO: 1,
    ERI: 1,
    MDG: 1,
    UGA: 1,
    TUN: 1,
    GIN: 1,
    GHA: 1,
    ABV: 1,
    GMB: 1,
    TCD: 1,
    GNB: 1,
    ZMB: 1,
    AGO: 1,
    MRT: 1,
    CAF: 1,
    GAB: 1,
    KEN: 1,
    EGY: 1,
    COD: 1,
    SDS: 1,
    COG: 1,
    MLI: 1,
    SLE: 1,
    MOZ: 1,
    SOM: 1,
    SEN: 1,
    TGO: 1,
    SDN: 1,
    NGA: 1,
    ESH: 1,
    CMR: 1,
    ZWE: 1,
    MWI: 1,
    LBR: 1,
    LBY: 1,
    MAR: 1,
    ZAF: 1,
    DZA: 1,
    CIV: 1,
    RWA: 1,
    TZA: 1,
    GNQ: 1,
}

export const africaBounds: LatLngBoundsExpression = [[38, -20], [-35, 54]];
