import { createTheme } from "@mui/material";

const redHexCode = '#f40009';
const redLightHexCode = '#ffc2c4';
const orangeHexCode = '#e98300';

export const customTheme = createTheme({
  palette: {
    primary: { main: redHexCode, light: redLightHexCode },
    secondary: { main: orangeHexCode }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          scrollbarColor: theme.palette.primary.main + ' ' + theme.palette.background.default,
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: theme.palette.background.default,
            borderRadius: 10,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 10,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: theme.palette.primary.main,
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: theme.palette.primary.main,
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.palette.primary.main,
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: 10,
          },
        }
      }),
    },
  },
});
