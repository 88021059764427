import React, { useContext, useState } from "react";
import { Box, Divider, FormControlLabel, Grid, Paper, Stack, Switch, Tab, Tabs, Typography, styled } from "@mui/material";
import { ProductCategoriesByAverageChart } from "../../../charts/ModelB/ProductCategoriesByAverageChart";
import { BaselinePricesAndIncreasesChart } from "../../../charts/ModelB/BaselinePricesAndIncreasesChart";
import { AveragePriceChangeChart } from "../../../charts/ModelB/AveragePriceChangeChart";
import { VolumeChartPerCategoryChart } from "../../../charts/ModelB/VolumeChartPerCategoryChart";
import { AverageChangePerGroupChart } from "../../../charts/ModelB/AverageChangePerGroupChart";
import { useTranslation } from "react-i18next";
import { NutrientsChart } from "../../../charts/ModelB/NutrientsChart";
import { ResetButtonB } from "../../Buttons/ResetButtonB";
import { TaxRevenuesChart } from "../../../charts/ModelB/TaxRevenuesChart";
import { ExportChartButton } from "../../../widgets/ExportChartButton";
import { HandleChart } from "../../../widgets/ExportChartsForPdfModelB";
import { FormBContext } from "../../../context/FormBContext";

const tabs = [{
    label: "Content",
    value: 0
}, {
    label: "Prices",
    value: 1
},
{
    label: "Volumes",
    value: 2
},
{
    label: "Tax revenues",
    value: 3
},
{
    label: "Nutrients",
    value: 4
}
]

const Item = styled(Stack)(() => ({
    textAlign: "center",
    alignItems: "center",
    padding: '8px',
    width: '100%',
    height: '100%'

}));

export const ChartsContainer = (props) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(0);
    const { getFormData } = useContext(FormBContext);
    const [isNewLevy, setIsNewLevy] = useState(false);
    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <Box role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} width="100%">
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography component="div">{children}</Typography>
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <div className="chartsContainer">
            {props.isCalculated && (
                <Box sx={{ width: "100%" }} id="charts">
                    <Stack gap='24px' width="90%" alignItems='center' mt='55px' mb='90px' mx='auto'>
                        <Box sx={{
                            width: '100%', borderRadius: "5px", border: "1px solid #797979", boxShadow: '0px 4px 4px 0px #00000040'
                        }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >

                                {tabs.map(({ label, value }) => (<Tab key={value} value={value} label={label} sx={{ flex: "1", fontSize: "16px", padding: '9px 16px', textTransform: 'none' }} />))}
                            </Tabs>
                        </Box>

                        <Paper variant='outlined' sx={{
                            width: '100%',
                            padding: '24px',
                            borderRadius: '12px',
                            border: '1px solid #797979',
                            boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2)',
                            p: "24px",
                            m: "auto",
                            display: 'flex',
                            flexDirection: 'column'
                        }}>

                            <TabPanel value={value} index={0}>
                                <Item
                                    className="chartContainer"
                                >
                                    <Box id="productCategoriesDescription" width='100%' height='100%' display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
                                        <ExportChartButton
                                            handleExport={() => HandleChart(getFormData(), "productCategoriesDescription", t(`sideBar.inputCurrency`))}
                                        />
                                        <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                                            <Typography sx={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                lineHeight: '24px',
                                                color: '#262626'

                                            }}>Product categories by average ingredient amounts</Typography>

                                            <Box sx={{
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                                backgroundColor: '#FF1D02'

                                            }} />

                                            <Typography sx={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                lineHeight: '19px',
                                                color: '#262626'
                                            }}>
                                                per 100g or 100ml
                                            </Typography>

                                        </Box>
                                        <ProductCategoriesByAverageChart />
                                    </Box>

                                </Item>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid
                                    container
                                    rowSpacing={0.5}
                                >
                                    <Grid item xs={isNewLevy ? 7.4 : 12}>
                                        <Item
                                            className="chartContainer"
                                        >
                                            <Box id="baselinePricesDescription" width='100%' height='100%' display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
                                            <FormControlLabel
                                            sx={{ ml: 6 }}
                                            control={<Switch size="small" checked={isNewLevy} onChange={(e) => setIsNewLevy(e.target.checked)} name="New Levy" />}
                                            label={isNewLevy ? "New Levy" : "Status Quo"}
                                            />
                                            <ExportChartButton
                                                handleExport={() => HandleChart(getFormData(), "baselinePricesDescription", t(`sideBar.inputCurrency`))}
                                            />
                                                <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                                                    <Typography sx={{
                                                        fontSize: '20px',
                                                        fontWeight: '600',
                                                        lineHeight: '24px',
                                                        color: '#262626'

                                                    }}>Baseline prices and increases per category</Typography>

                                                    <Box sx={{
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#FF1D02'

                                                    }} />

                                                    <Typography sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '400',
                                                        lineHeight: '19px',
                                                        color: '#262626'
                                                    }}>
                                                        in EUR per kg or litres
                                                    </Typography>

                                                </Box>
                                                <BaselinePricesAndIncreasesChart isNewLevy={isNewLevy} />
                                            </Box>
                                        </Item>
                                    </Grid>


                                   {  isNewLevy && (<>
                                    <Grid item xs={0.2} display='flex' justifyContent='center'>
                                        <Divider orientation="vertical" />
                                    </Grid>


                                    <Grid item xs={4.4}>
                                        <Item
                                            className="chartContainer"
                                        >
                                            <Box id="averagePricesDescription" width='100%' height='100%' display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
                                            <ExportChartButton
                                                handleExport={() => HandleChart(getFormData(), "averagePricesDescription", t(`sideBar.inputCurrency`))}
                                            />
                                                <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                                                    <Typography sx={{
                                                        fontSize: '20px',
                                                        fontWeight: '600',
                                                        lineHeight: '24px',
                                                        color: '#262626'

                                                        }}>Average change per group</Typography>

                                                        <Box sx={{
                                                            width: '8px',
                                                            height: '8px',
                                                            borderRadius: '50%',
                                                            backgroundColor: '#FF1D02'

                                                        }} />

                                                        <Typography sx={{
                                                            fontSize: '16px',
                                                            fontWeight: '400',
                                                            lineHeight: '19px',
                                                            color: '#262626'
                                                        }}>
                                                            in percentages %
                                                        </Typography>

                                                    </Box>
                                                    <AveragePriceChangeChart />
                                                </Box>
                                            </Item>
                                        </Grid>
                                    </>)}

                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Grid
                                    container
                                    rowSpacing={0.5}
                                >
                                    <Grid item xs={7.4}>
                                        <Item
                                            className="chartContainer"
                                        >
                                            <Box id="volumeChangeDescription" width='100%' height='100%' display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
                                                <ExportChartButton handleExport={() => HandleChart(getFormData(), "volumeChangeDescription", t(`sideBar.inputCurrency`))} />
                                                <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                                                    <Typography sx={{
                                                        fontSize: '20px',
                                                        fontWeight: '600',
                                                        lineHeight: '24px',
                                                        color: '#262626'

                                                    }}>Volume change per category</Typography>

                                                    <Box sx={{
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#FF1D02'

                                                    }} />

                                                    <Typography sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '400',
                                                        lineHeight: '19px',
                                                        color: '#262626'
                                                    }}>
                                                        in percentages %
                                                    </Typography>

                                                </Box>
                                                <VolumeChartPerCategoryChart />
                                            </Box>
                                        </Item>
                                    </Grid>

                                    <Grid item xs={0.2} display='flex' justifyContent='center'>
                                        <Divider orientation="vertical" />
                                    </Grid>


                                    <Grid item xs={4.4}>
                                        <Item
                                            className="chartContainer"
                                        >
                                            <Box id="averageChangeDescription" width='100%' height='100%' display='flex' flexDirection='column' justifyContent='space-between' alignItems='center' px='10px'>
                                                <ExportChartButton
                                                    handleExport={() => HandleChart(getFormData(), "averageChangeDescription", t(`sideBar.inputCurrency`))}
                                                />
                                                <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                                                    <Typography sx={{
                                                        fontSize: '20px',
                                                        fontWeight: '600',
                                                        lineHeight: '24px',
                                                        color: '#262626'

                                                    }}>Average change per group</Typography>

                                                    <Box sx={{
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#FF1D02'

                                                    }} />

                                                    <Typography sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '400',
                                                        lineHeight: '19px',
                                                        color: '#262626'
                                                    }}>
                                                        in percentages %
                                                    </Typography>

                                                </Box>
                                                <AverageChangePerGroupChart />
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Item
                                    className="chartContainer"
                                >
                                    <Box id="taxRevenuesDescription" width='100%' height='100%' display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
                                        <ExportChartButton handleExport={() => HandleChart(getFormData(), "taxRevenuesDescription", t(`sideBar.inputCurrency`))} />
                                        <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                                            <Typography sx={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                lineHeight: '24px',
                                                color: '#262626'

                                            }}>Tax revenues outcomes</Typography>

                                            <Box sx={{
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                                backgroundColor: '#FF1D02'

                                            }} />

                                            <Typography sx={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                lineHeight: '19px',
                                                color: '#262626'
                                            }}>
                                                € million, % change
                                            </Typography>

                                        </Box>
                                        <TaxRevenuesChart />
                                    </Box>
                                </Item>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <Grid
                                    container
                                    rowSpacing={0.5}
                                >
                                    <Grid item xs={5.9}>
                                        <Item
                                            className="chartContainer"
                                        >
                                            <Box id="changeInConsumptionDescriptionAbsolute" width='100%' height='100%' display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
                                                <ExportChartButton
                                                    handleExport={() => HandleChart(getFormData(), "changeInConsumptionDescriptionAbsolute", t(`sideBar.inputCurrency`))}
                                                />
                                                <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                                                    <Typography sx={{
                                                        fontSize: '20px',
                                                        fontWeight: '600',
                                                        lineHeight: '24px',
                                                        color: '#262626'

                                                    }}>Change in consumption of nutrients</Typography>

                                                    <Box sx={{
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#FF1D02'

                                                    }} />

                                                    <Typography sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '400',
                                                        lineHeight: '19px',
                                                        color: '#262626'
                                                    }}>
                                                        in tonnes
                                                    </Typography>

                                                </Box>
                                                <NutrientsChart type="constant" />
                                            </Box>
                                        </Item>
                                    </Grid>

                                    <Grid item xs={0.2} display='flex' justifyContent='center'>
                                        <Divider orientation="vertical" />
                                    </Grid>


                                    <Grid item xs={5.9}>
                                        <Item
                                            className="chartContainer"
                                        >
                                            <Box id="changeInConsumptionDescription" width='100%' height='100%' display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
                                                <ExportChartButton
                                                    handleExport={() => HandleChart(getFormData(), "changeInConsumptionDescription", t(`sideBar.inputCurrency`))}
                                                />
                                                <Box display='flex' gap='8px' justifyContent='center' alignItems='center' mb='40px' pt='72px'>
                                                    <Typography sx={{
                                                        fontSize: '20px',
                                                        fontWeight: '600',
                                                        lineHeight: '24px',
                                                        color: '#262626'

                                                    }}>Change in consumption of nutrients</Typography>

                                                    <Box sx={{
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#FF1D02'

                                                    }} />

                                                    <Typography sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '400',
                                                        lineHeight: '19px',
                                                        color: '#262626'
                                                    }}>
                                                        in percentages %
                                                    </Typography>
                                                </Box>
                                                <NutrientsChart />
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <ResetButtonB setIsCalculated={props.setIsCalculated} text={t("sideBar.resetButton")} className="reset"></ResetButtonB>
                        </Paper>
                    </Stack>
                </Box>
            )}
        </div>
    );
};
