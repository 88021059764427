import React, { useContext } from 'react';
import { BarDatum, BarItem, BarItemProps } from '@nivo/bar'
import { blackColorWithOpacity } from '../../components/ChartColors';
import { FormAContext } from '../../context/FormAContext';
import { useTheme } from '@nivo/core';
import BarChart from '../../widgets/BarChart';
import { formatNoDecimals } from '../../widgets/FormatNumber';
import Tooltip from '../../components/Tooltip';

const BarWithTotals = (props: BarItemProps<BarDatum>) => {

    const theme = useTheme();

    if (props.bar.key === 'Government.Pre-tax' || props.bar.key ==="Coca-Cola.Post-tax") // Last key
    {
        const bar = props.bar;
        const total = props.bar.data.data.total;

        const transform = `translate(${bar.absX + bar.width - 30}, ${
            bar.absY + bar.height / 2
          })`;


        return <>
            <BarItem {...props} />
            <g transform={transform}>
                <text
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        ...theme.labels.text,
                        pointerEvents: 'none',
                    }}>{total}</text>
            </g>
        </>
    }
    else {
        return <BarItem {...props} />;
    }
}

function CoreChart() {
    const { results } = useContext(FormAContext);
    if (results) {
        const effect = results.total_effect_on_value;

        const data = [
            {
                "tax": "Pre-tax",
                "Coca-Cola": effect.coca_cola_pretax,
                "Trade": effect.trade_pretax,
                "Government": effect.gov_pretax,
                "total": formatNoDecimals(effect.coca_cola_pretax + effect.trade_pretax + effect.gov_pretax)
            },
            {
                "tax": "Post-tax",
                "Coca-Cola": effect.coca_cola_posttax,
                "Trade": effect.trade_posttax,
                "Government": effect.gov_posttax,
                "total": formatNoDecimals(effect.coca_cola_posttax + effect.trade_posttax + effect.gov_posttax)
            },
        ];

        return <BarChart
            data={data}
            keys={[
                'Coca-Cola',
                'Trade',
                'Government'
            ]}

            axisMarginHigh
            minValue={'auto'}

            label={d => `${formatNoDecimals(d.value)}`}
            indexBy="tax"
            margin={{ top: 0, right: 60, bottom: 0, left: 70 }}
            padding={0.25}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={blackColorWithOpacity}
            colorBy="id"
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
            }}
            axisBottom={null}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"

            enableGridX={false}
            enableGridY={false}

            theme={{
                fontSize:16,
                textColor:'#474747',
            }}

            // @ts-ignore
            barComponent={BarWithTotals}
            tooltip={e => <Tooltip>
                {e.id ? `${e.id}-${e.indexValue} ` : ""}
                {e.value
                    ? formatNoDecimals(e.value)
                    : "No data"}
            </Tooltip>
            }
        />
    }
    else {
        return <div> No data </div>;
    }
}

export const ChangeInConsumptionChart = () => {
    return <div style={{ width: "100%", position: "relative" , display:'flex' , flexDirection: 'column' , gap:'24px' }}>
         <div style={{ height: "109px"}}>

        <CoreChart />
         </div>
        <div className='legend'>
            <div className='rect blackRect'></div> <span>Coca-Cola</span>
            <div className='rect blackRectOpa60'></div><span>Trade</span>
            <div className='rect blackRectOpa30'></div><span>Government</span>
        </div>
    </div>
}
