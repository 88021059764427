import React, { useContext} from "react";
import { orangeColor } from "../../components/ChartColors";
import Tooltip from "../../components/Tooltip";
import { FormAContext } from "../../context/FormAContext";
import BarChart from "../../widgets/BarChart";
import { formatNoDecimals } from "../../widgets/FormatNumber";
import { BarDatum, BarItem, BarItemProps } from '@nivo/bar';


const CustomBarComponent = (props: BarItemProps<BarDatum>) => {
    const bar = props.bar;

    const {x , y, width , height , data} = bar

    return <>
            <BarItem {...props} />
            <g>
            <text
                x={x + width / 2}
                y={y + height + 15} // the important bit!!
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: 16,
                    fontWeight:400,
                    lineHeight:'19px',
                    pointerEvents: "none",
                    fill: "#000",
                    opacity: 0.75,
                    maxWidth: "85px",
                    wordBreak: "break-all",
                }}
            >
                {data.indexValue}
            </text>
            </g>
        </>
}

function CoreChart() {
    const { results } = useContext(FormAContext);
    if (results) {
        const effect = results.total_effect_on_income;
        const data = [
            {
                type: "Coca-Cola",
                value: effect["Coca-Cola"],
            },
            {
                type: "Government",
                value: effect.Government,
            },
            {
                type: "Households & firms",
                value: effect.Households_and_firms,
            },
            {
                type: "Net effect on local economy",
                value: effect.Net_effect_on_local,
            },
        ];

        return (
            <BarChart
                data={data}
                keys={["value"]}
                indexBy="type"
                axisMarginHigh
                axisMarginLow
                margin={{ top: 5, right: 0, bottom: 50, left: 70 }}
                padding={0.4}
                layout="vertical"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={orangeColor}
                colorBy="id"
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisBottom={null}
                axisRight={null}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: (value) => `${Number(value)} `,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor="white"
                enableGridX={false}
                enableGridY={false}
                label={(d) => `${formatNoDecimals(d.value)} `}
                barComponent={CustomBarComponent}
                theme={{
                    fontSize: 16,
                    textColor: "#474747",
                }}
                tooltip={(e) => (
                    <Tooltip>
                        {e.id ? `${e.indexValue}: ` : ""}
                        {e.value ? `${formatNoDecimals(e.value)} ` : "No data"}
                    </Tooltip>
                )}
            />
        );
    } else {
        return <div> No data </div>;
    }
}

export const ChangeInIncomeChart = () => {
    return (
        <div style={{ height: "360px", width: "100%" }}>
            <CoreChart />
        </div>
    );
};
