import React, { useContext } from "react";
import { CalorieValues } from "../api/modelAObjects";
import { NumberInput } from "../components/Inputs/NumberInput";
import { FormAContext } from "../context/FormAContext";
import "./InputGroup.scss";

type Props = {
    groupName: string;
    isDisabled: boolean;
};

export const convertToDisplayValues = (data: CalorieValues) => {
    const flooredLowCalorie = Math.floor(data.LOWCALORIES * 100);
    const flooredNoCalorie = Math.floor(data.NOCALORIES * 100);
    const flooredRegular = Math.floor(data.REGULAR * 100);
    const flooredData = flooredLowCalorie + flooredNoCalorie + flooredRegular;

    const diff = ((data.LOWCALORIES + data.NOCALORIES + data.REGULAR) * 100) - flooredData;

    return {
        NOCALORIES: flooredNoCalorie,
        LOWCALORIES: flooredLowCalorie,
        REGULAR: flooredRegular + diff
    }
}

export const PercentageInputGroup = ({ groupName }: Props) => {
    const {
        portfolioSplit, setPortfolioSplit,
        isActualPortfolioSelected, updateActualPortFolio
    } = useContext(FormAContext);

    const displayValues = convertToDisplayValues(portfolioSplit);

    const handleLowSplitChange = (value: any) => { setPortfolioSplit({ ...portfolioSplit, NOCALORIES: value / 100 }); }
    const handleMidSplitChange = (value: any) => { setPortfolioSplit({ ...portfolioSplit, LOWCALORIES: value / 100 }); }
    const handleHighSplitChange = (value: any) => { setPortfolioSplit({ ...portfolioSplit, REGULAR: value / 100 }); }

    return <div className='singleInputContainer'>
        <NumberInput
            disabled={isActualPortfolioSelected}
            onChange={handleLowSplitChange}
            value={displayValues.NOCALORIES}
            name={`${groupName}<5`}
            digits={0}
            forceUpdate={updateActualPortFolio}
            isPositive={true}
        />
        <NumberInput
            disabled={isActualPortfolioSelected}
            onChange={handleMidSplitChange}
            value={displayValues.LOWCALORIES}
            name={`${groupName}5-8`}
            digits={0}
            forceUpdate={updateActualPortFolio}
            isPositive={true}
        />
        <NumberInput
            disabled={isActualPortfolioSelected}
            onChange={handleHighSplitChange}
            value={displayValues.REGULAR}
            name={`${groupName}>8`}
            digits={0}
            forceUpdate={updateActualPortFolio}
            isPositive={true}
        />
    </div>
};

export const TaxRateInputGroup = ({ groupName }: Props) => {

    const { taxRates, setTaxRates } = useContext(FormAContext);
    const handleLowTaxChange = (value: any) => {
        setTaxRates({ ...taxRates, NOCALORIES: value });
    };
    const handleMidTaxChange = (value: any) => {
        setTaxRates({ ...taxRates, LOWCALORIES: value });
    };
    const handleHighTaxChange = (value: any) => {
        setTaxRates({ ...taxRates, REGULAR: value });
    };

    return (
        <div className="singleInputContainer">
            <NumberInput
                disabled={false}
                onChange={handleLowTaxChange}
                value={taxRates.NOCALORIES}
                name={`${groupName}<5`}
                digits={0}
                forceUpdate={0}
                isPositive={true}
            />
            <NumberInput
                disabled={false}
                onChange={handleMidTaxChange}
                value={taxRates.LOWCALORIES}
                name={`${groupName}5-8`}
                digits={0}
                forceUpdate={0}
                isPositive={true}
            />
            <NumberInput
                disabled={false}
                onChange={handleHighTaxChange}
                value={taxRates.REGULAR}
                name={`${groupName}>8`}
                digits={0}
                forceUpdate={0}
                isPositive={true}
            />
        </div>
    );
};
