import React from "react"
import './Footer.scss'
import logo from "../assets/g3.png";
import { Link } from "react-router-dom";
import * as Paths from '../Paths';

const Footer = () => {
    return <footer className="footer">
        <div className="footerContainer">
        <Link className='breadCumbsLink' to={Paths.root.path} ><img className='footerLogo' src={logo} alt="Coca Cola Logo" /> </Link>
        <p className="footerSubtitle">Powered by <strong>Steward Redqueen</strong></p>

        </div>
    </footer>
}

export default Footer