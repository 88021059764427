import React, { useContext, useEffect, useState } from "react";
import {
    TaxRateInputGroup,
    PercentageInputGroup,
} from "../../../widgets/InputGroup";
import { useTranslation } from "react-i18next";
import "./ModelAInputGroup.scss";
import { FormAContext } from "../../../context/FormAContext";
import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import BpCheckedIcon from "../../Inputs/BpCheckedIcon";
import BpIcon from "../../Inputs/BpIcon";
import { NumberInput } from "../../Inputs/NumberInput";

const checkPercentage = (data) => {
    const total = (data.NOCALORIES) + (data.LOWCALORIES) + (data.REGULAR);
    return total
}

const ModelAInputGroup = () => {

    const { t } = useTranslation();
    const {
        fcElasticity, setFcElasticity,
        icElasticity, setIcElasticity,
        isDisabled,
        isBestEstimatesSelected, setIsBestEstimatesSelected,
        isActualPortfolioSelected, setIsActualPortfolioSelected,
        updateElasticities, portfolioSplit
    } = useContext(FormAContext);

    const [portfolioSplitPercentage, setPortfolioSplitPercentage] = useState((portfolioSplit.LOWCALORIES * 100) + (portfolioSplit.NOCALORIES * 100) + (portfolioSplit.REGULAR * 100))

    const handleIcChange = (e: number | null) => {
        setIcElasticity(e);
    };

    const handleFcChange = (e: number | null) => {
        setFcElasticity(e);
    };

    useEffect(() => {
        setPortfolioSplitPercentage(checkPercentage(portfolioSplit))
    }, [portfolioSplit]);

    return (
        <div className="modelAInputGroupComponent">
            <div className="singleRowComponent">
                <div className="emptyDiv"></div>
                <div style={{
                    width:'95%',
                    display:'flex',
                    justifyContent:"space-between"
                }}>
                    <p className="headerTitle">{t("modelA.noCalorie")}</p>
                    <p className="headerTitle">{t("modelA.lowCalorie")}</p>
                    <p className="headerTitle">{t("modelA.regular")}</p>
                </div>
            </div>
            <div className="singleRowComponent" style={{marginBottom:'16px'}}>
                <p className=" taxRateTitle">{t("modelA.inputGroups.taxRate")}</p>
                <div className="taxInputGroup">
                    <TaxRateInputGroup groupName="taxRate" isDisabled={isDisabled} />
                </div>
            </div>
            <div className="singleRowComponent">
                <p className=" taxRateTitle">
                    {t("modelA.inputGroups.portfolioSplit")}
                </p>
                <div className="taxInputGroup">
                    <PercentageInputGroup groupName="portfolio" isDisabled={isDisabled} />
                    {
                        (Math.abs(1 - portfolioSplitPercentage) > 0.0000001)
                        && <span className='inputNotification'>Portfolio split total value should be 100</span>
                    }
                </div>
            </div>
            <div className="singleRowComponent">
                {/* <p className=" taxRateTitle"></p> */}
                <div className="taxInputGroup checkboxContainer">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                            value="portfolio"
                            sx={{
                                marginRight:'0',
                                // width:'170px',
                                // justifyContent:'space-between'
                            }}  
                            control={
                                <Checkbox
                                    sx={{
                                        color: red[800],
                                        "&.Mui-checked": {
                                            color: red[600],
                                        },
                                        "&.MuiCheckbox-root": {
                                            borderRadius: '3px',
                                            width: '18px',
                                            height: '18px',
                                            boxShadow: 'inset 0 0 0 2px rgba(0, 0, 0, 0.6), inset 0 -1px 0 rgba(0, 0, 0, 0.6)',
                                            backgroundColor: '#fff',
                                            marginRight:'9px'
                                        }
                                    }}
                                    checkedIcon={<BpCheckedIcon />}
                                    icon={<BpIcon />}
                                    checked={isActualPortfolioSelected}
                                    onChange={(e) => {
                                        setIsActualPortfolioSelected(e.target.checked);
                                    }
                                    }
                                />
                            }
                            label={
                                <Typography
                                    sx={{
                                        fontSize: '16px', // Adjust as needed
                                        fontWeight: '400',
                                        lineHeight: '24px',
                                        color: 'rgba(0, 0, 0, 0.67)', // Adjust label color
                                    }}
                                >
                                    {t("modelA.useActualPortfolio")}
                                </Typography>
                            }
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="singleRowComponent">
                <div className="emptyDiv"></div>
                <div style={{
                    width:'95%',
                    display:'flex',
                    justifyContent:"space-between"
                }}>
                    <p className="headerTitle IC ">{t("modelA.immediateConsumption")}</p>
                    <p className="headerTitle FC">{t("modelA.futureConsumption")}</p>
                </div>
            </div>
            <div className="singleRowComponent">
                <p className=" taxRateTitle">{t("modelA.inputGroups.elasticity")}</p>
                <div className="taxInputGroup elasticityInputGroup">
                    <NumberInput
                        disabled={isBestEstimatesSelected}
                        onChange={handleIcChange}
                        value={icElasticity}
                        name="elasticityic"
                        forceUpdate={updateElasticities}
                        isPositive={false}
                    />
                    <NumberInput
                        disabled={isBestEstimatesSelected}
                        onChange={handleFcChange}
                        value={fcElasticity}
                        name="elasticityfc"
                        forceUpdate={updateElasticities}
                        isPositive={false}
                    />
                </div>
            </div>
            <div className="singleRowComponent">
                {/* <p className=" taxRateTitle"></p> */}
                <div className="taxInputGroup checkboxContainerBestEstimates">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                            value="estimates"
                            sx={{
                                marginRight:'0',
                                // width:'170px',
                                // justifyContent:'space-between'
                            }}       
                            control={
                                <Checkbox
                                    sx={{
                                        color: red[800],
                                        "&.Mui-checked": {
                                            color: red[600],
                                        },
                                        // marginRight: "8px",
                                        "&.MuiCheckbox-root": {
                                            borderRadius: '3px',
                                            width: '18px',
                                            height: '18px',
                                            boxShadow: 'inset 0 0 0 2px rgba(0, 0, 0, 0.6), inset 0 -1px 0 rgba(0, 0, 0, 0.6)',
                                            backgroundColor: '#fff',
                                            marginRight:'9px'
                                        }
                                        
                                    }}
                                    checkedIcon={<BpCheckedIcon />}
                                    checked={isBestEstimatesSelected}
                                    icon={<BpIcon />}
                                    onChange={(e) => {
                                        setIsBestEstimatesSelected(e.target.checked);
                                    }}
                                />
                            }
                            label={
                                <Typography
                                    sx={{
                                        fontSize: '16px', // Adjust as needed
                                        fontWeight: '400',
                                        lineHeight: '24px',
                                        color: 'rgba(0, 0, 0, 0.67)', // Adjust label color
                                    }}
                                >
                                    {t("modelA.useElasticityEstimates")}
                                </Typography>
                            }
                        />
                    </FormGroup>
                </div>
            </div>
        </div>
    );
};

export default ModelAInputGroup;
