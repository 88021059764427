import React, { useState, createContext, useEffect } from 'react';
import { CalorieValues, defaultsA, ModelAType } from '../api/modelAObjects';
import { convertToDisplayValues } from '../widgets/InputGroup';

type Results = {
    "effect_on_beverage_price": {
        "result_fc": number,
        "result_ic": number
    },
    "employment_effect": {
        "jobs": number
    },
    "total_effect_on_income": {
        "Coca-Cola": number,
        "Government": number,
        "Households_and_firms": number,
        "Net_effect_on_local": number
    },
    "total_effect_on_value": {
        "coca_cola_posttax": number,
        "coca_cola_pretax": number,
        "gov_posttax": number,
        "gov_pretax": number,
        "trade_posttax": number,
        "trade_pretax": number
    },
    "total_effect_on_volume": {
        "change": number,
        "high_cal_posttax": number,
        "high_cal_pretax": number,
        "low_cal_posttax": number,
        "low_cal_pretax": number,
        "no_cal_posttax": number,
        "no_cal_pretax": number
    }
}

export type ContexAType = {
    countries: { europe: Array<string>, africa: Array<string> }

    country: string,
    setCountry: (a: string) => void;

    isCountrySelected: boolean;
    setIsCountrySelected: (a: boolean) => void;
    isActualPortfolioSelected: boolean;
    setIsActualPortfolioSelected: (a: boolean) => void;
    isBestEstimatesSelected: boolean;
    setIsBestEstimatesSelected: (a: boolean) => void;

    updateActualPortFolio: number,
    updateElasticities: number,

    taxRates: CalorieValues;
    setTaxRates: (a: CalorieValues) => void;

    portfolioSplit: CalorieValues;
    setPortfolioSplit: (a: CalorieValues) => void;

    pdfPortfolioValues: CalorieValues;
    setPdfPortfolioValues: (values: CalorieValues) => void;

    fcElasticity: number | null;
    setFcElasticity: (a: number | null) => void;

    icElasticity: number | null;
    setIcElasticity: (a: number | null) => void;

    isDisabled: boolean;
    setIsDisabled: (a: boolean) => void;

    getFormData: () => ModelAType;
    getPdfData: () => ModelAType;


    results: Results | null;
    setResults: (results: Results | null) => void;

    areInputsInvalid: boolean;
    setAreInputsInvalid: (a: boolean) => void;

}

type Props = {
    children: any,
};

type TaxRates = {
    NOCALORIES: number,
    LOWCALORIES: number,
    REGULAR: number
}

function convertFromEurosToCents(taxRates: TaxRates): TaxRates {
    const taxRatesInCents = { ...taxRates };
    for (const rate of Object.keys(taxRatesInCents)) {
        taxRatesInCents[rate] *= 100;
    }
    return taxRatesInCents;
}

const defaultModelACountries = { europe: [], africa: [] };

export const FormAContext = createContext<ContexAType>({
    countries: defaultModelACountries,
    country: defaultsA.country, setCountry() { },
    isCountrySelected: false, setIsCountrySelected() { },
    isActualPortfolioSelected: false, setIsActualPortfolioSelected() { },
    isBestEstimatesSelected: false, setIsBestEstimatesSelected() { },
    updateActualPortFolio: 1,
    updateElasticities: 1,
    taxRates: { ...defaultsA.tax_rates }, setTaxRates() { },
    portfolioSplit: { ...defaultsA.portfolio_split }, setPortfolioSplit() { },
    pdfPortfolioValues: { ...defaultsA.portfolio_split }, setPdfPortfolioValues() { },
    fcElasticity: defaultsA.fc_elasticity, setFcElasticity() { },
    icElasticity: defaultsA.ic_elasticity, setIcElasticity() { },
    isDisabled: false, setIsDisabled() { },
    getFormData() { return defaultsA },
    getPdfData() { return defaultsA },
    results: null, setResults() { },
    areInputsInvalid: false, setAreInputsInvalid() { },

});

const FormAContextProvider = (props: Props) => {
    const [countries, setCountries] = useState(defaultModelACountries);
    const [country, setCountry] = useState(defaultsA.country);
    const [isCountrySelected, setIsCountrySelected] = useState(false);
    const [isActualPortfolioSelected, setIsActualPortfolioSelected] = useState(false);
    const [isBestEstimatesSelected, setIsBestEstimatesSelected] = useState(false);
    const [updateActualPortFolio, setUpdateActualPortFolio] = useState(1);
    const [updateElasticities, setUpdateElasticities] = useState(1);

    const [taxRates, setTaxRates] = useState({ ...defaultsA.tax_rates });
    const [portfolioSplit, setPortfolioSplit] = useState({ ...defaultsA.portfolio_split });
    const [pdfPortfolioValues, setPdfPortfolioValues] = useState({ ...defaultsA.portfolio_split })
    const [fcElasticity, setFcElasticity] = useState<number | null>(defaultsA.fc_elasticity);
    const [icElasticity, setIcElasticity] = useState<number | null>(defaultsA.ic_elasticity);
    const [isDisabled, setIsDisabled] = useState(false);
    const [results, setResults] = useState<Results | null>(null);
    const [areInputsInvalid, setAreInputsInvalid] = useState(false);

    useEffect(() => {
        fetch('/api/modela/countries')
            .then(r => r.json())
            .then(c => {
                setCountries(c);
            });
    }, []);

    useEffect(() => {
        if (isActualPortfolioSelected) {
            fetch('/api/modela/defaults?' + new URLSearchParams({ country: country }), { method: 'GET', })
                .then(r => r.json())
                .then(response => {
                    setPortfolioSplit({
                        NOCALORIES: response.portfolio_actual.no_calorie,
                        LOWCALORIES: response.portfolio_actual.low_calorie,
                        REGULAR: response.portfolio_actual.regular
                    });
                    setUpdateActualPortFolio((u) => u + 1);
                })
                .catch(error => console.log('Fetch error', error))
        }
        else {
            setPortfolioSplit({
                NOCALORIES: 0,
                LOWCALORIES: 0,
                REGULAR: 0
            });

            setUpdateActualPortFolio((u) => u + 1);
        }
    }, [country, isActualPortfolioSelected]);

    useEffect(() => {
        if (isBestEstimatesSelected) {
            fetch('api/modela/defaults?' + new URLSearchParams({ country: country }), { method: 'GET', })
                .then(r => r.json())
                .then(response => {

                    setIcElasticity(response.elasticity_estimates.IC);
                    setFcElasticity(response.elasticity_estimates.FC);

                    setUpdateElasticities((u) => u + 1)
                })
                .catch(error => console.log('Fetch error', error))

        }
        else {
            setIcElasticity(0);
            setFcElasticity(0);
            setUpdateElasticities((u) => u + 1)
        }
    }, [country, isBestEstimatesSelected]);

    const getFormData = () => {
        return {
            country: country,
            tax_rates: convertFromEurosToCents(taxRates),
            portfolio_split: portfolioSplit,
            fc_elasticity: fcElasticity ?? 0,
            ic_elasticity: icElasticity ?? 0,
        };
    };
    const getPdfData = () => {
        return {
            country: country,
            tax_rates: convertFromEurosToCents(taxRates),
            portfolio_split: pdfPortfolioValues,
            fc_elasticity: fcElasticity ?? 0,
            ic_elasticity: icElasticity ?? 0,
        };
    };

    useEffect(() => {
        setAreInputsInvalid(
            (portfolioSplit.LOWCALORIES + portfolioSplit.NOCALORIES + portfolioSplit.REGULAR === 0) ||
            (taxRates.LOWCALORIES + taxRates.NOCALORIES + taxRates.REGULAR === 0) ||
            icElasticity == null || fcElasticity == null
        )
    }, [fcElasticity, icElasticity,
        portfolioSplit.LOWCALORIES, portfolioSplit.NOCALORIES, portfolioSplit.REGULAR,
        taxRates.LOWCALORIES, taxRates.NOCALORIES, taxRates.REGULAR])

    useEffect(() => {
        const convertedValues = convertToDisplayValues(portfolioSplit)
        const valueDivided = {
            NOCALORIES: convertedValues.NOCALORIES / 100,
            LOWCALORIES: convertedValues.LOWCALORIES / 100,
            REGULAR: convertedValues.REGULAR / 100
        }
        setPdfPortfolioValues(valueDivided)
    }, [portfolioSplit])

    return (
        <FormAContext.Provider value={{
            countries,
            country, setCountry,
            isCountrySelected, setIsCountrySelected,
            isActualPortfolioSelected, setIsActualPortfolioSelected,
            isBestEstimatesSelected, setIsBestEstimatesSelected,
            updateActualPortFolio, updateElasticities,
            taxRates, setTaxRates,
            portfolioSplit, setPortfolioSplit,
            fcElasticity, setFcElasticity,
            icElasticity, setIcElasticity,
            isDisabled, setIsDisabled,
            getFormData, getPdfData,
            results, setResults,
            areInputsInvalid, setAreInputsInvalid,
            pdfPortfolioValues, setPdfPortfolioValues
        }}>
            {props.children}
        </FormAContext.Provider>
    );
}

export default FormAContextProvider;


