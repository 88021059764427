import { Radio } from '@mui/material';
import { red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

export const CcRadio = styled(Radio)({
    ':not(disabled)': {
        color: red[800],
        '&.Mui-checked': {
            color: red[600],
        },
    }
});