import { styled } from "@mui/material/styles";

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 3,
    width: 20,
    height: 20,
    boxShadow: "inset 0 0 0 3px red, inset 0 -1px 0 red",
    backgroundColor: "#f5f8fa"
}));

export default BpIcon;