import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const HandleChart = (data, chartId) => {

    const input = document.getElementById(chartId);
    // @ts-ignore
    html2canvas(input, { scale: 0.85 })
        .then((canvas) => {
            const pdf = new jsPDF('p', 'px', 'A4');
            const imgData = canvas.toDataURL('image/png');

            pdf.addImage(imgData, 'PNG', 10, 30, 0, 0);

            pdf.text("INPUT DETAILS", 30, 300)
            pdf.setFontSize(13);
            pdf.text(`Country: ${data.country}`, 30, 320);
            pdf.text(`Fc Elasticity: ${data.fc_elasticity}`, 30, 340);
            pdf.text(`Ic Elasticity: ${data.ic_elasticity}`, 30, 360);

            pdf.text(`Portfolio Split NO CALORIES: ${(data.portfolio_split.NOCALORIES * 100).toFixed(0)}`, 30, 380);
            pdf.text(`Portfolio Split LOW CALORIES: ${(data.portfolio_split.LOWCALORIES * 100).toFixed(0)}`, 30, 400);
            pdf.text(`Portfolio Split REGULAR: ${(data.portfolio_split.REGULAR * 100).toFixed(0)}`, 30, 420);
            pdf.text(`Tax Rate NOCALORIES: ${data.tax_rates.NOCALORIES}`, 30, 440);
            pdf.text(`Tax Rate LOWCALORIES: ${data.tax_rates.LOWCALORIES}`, 30, 460);
            pdf.text(`Tax Rate REGULAR: ${data.tax_rates.REGULAR}`, 30, 480);
            pdf.save("download.pdf");
        });
}
