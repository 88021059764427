import React, { useContext } from "react";
import { BarItem, BarItemProps, BarTooltipProps, ComputedDatum } from "@nivo/bar";
import { lightRedColor, redColor } from "../../components/ChartColors";
import { FormBContext } from "../../context/FormBContext";
import { useTheme } from "@nivo/core";
import BarChart from "../../widgets/BarChart";
import Tooltip from "../../components/Tooltip";
import { NutrientType } from "../../api/modelBObjects";

const padding = 0.52;
const margins = { top: 20, right: 20, bottom: 70, left: 20 };
const labelSkipHeight = 12;

// Global warning, you can't use two of these charts in one page without problems.
// If you have lots of different data sets this might use lots of memory.
const bars = {};

const vatKey = "VAT";
const exciseTaxSugarKey = "Excise Tax Sugar";
const exciseTaxSaltKey = "Excise Tax Salt";

function percentageToString(increase_percent: number) {
    let increasePercentage: string;
    if (increase_percent === Infinity) {
        increasePercentage = "N/A %";
    } else if (increase_percent !== undefined) {
        increasePercentage = increase_percent.toFixed(0) + "%";
    } else {
        increasePercentage = "0%";
    }

    if (increase_percent >= 0) {
        increasePercentage = "+" + increasePercentage;
    }
    return increasePercentage;
}

function absoluteToString(increase_absolute: number) {
    if (increase_absolute === undefined) {
        return "Not applicable";
    }
    return "€ " + (increase_absolute / 1000000).toFixed(0) + " million";
}

const BarWithTotals = (props: BarItemProps<{ [key: string]: number }>) => {
    const theme = useTheme();
    const bar = props.bar;
    const transform = `translate(${bar.x + bar.width / 2}, ${bar.y - labelSkipHeight})`;
    bars[props.bar.key] = props.bar;

    // show increase lines and totals for salt, only second and third bars
    if (props.bar.data.id === exciseTaxSaltKey && !props.bar.key.includes("Status quo")) {
        const total = props.bar.data.data.total;

        const paddingWidth = padding * (bar.width / (1 - padding));
        const transformIncreaseLabel = `translate(${bar.x - paddingWidth / 2}, ${bar.y - labelSkipHeight})`;

        let increasePercentage = percentageToString(props.bar.data.data.increase_percent);
        let prevBar; // set previous bar based on if keys exist
        if (props.bar.key === exciseTaxSaltKey + ".New levy") {
            prevBar = bars[exciseTaxSaltKey + ".Status quo"];
        }

        return (
            <>
                <BarItem {...props} />
                <g transform={transform}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{
                            ...theme.labels.text,
                            pointerEvents: "none",
                        }}
                    >
                        {total}
                    </text>
                </g>
                <line style={{ strokeWidth: 1, opacity: 1, stroke: "black" }} x1={prevBar.x + prevBar.width} y1={prevBar.y} x2={props.bar.x} y2={props.bar.y} />
                <g transform={transformIncreaseLabel}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{
                            ...theme.labels.text,
                            pointerEvents: "none",
                        }}
                    >
                        {increasePercentage}
                    </text>
                </g>
            </>
        );
    } else if (props.bar.data.id === exciseTaxSaltKey && props.bar.key.includes("Status quo")) {
        const total = props.bar.data.data.total;
        return (
            <>
                <BarItem {...props} />
                <g transform={transform}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{
                            ...theme.labels.text,
                            pointerEvents: "none",
                        }}
                    >
                        {total}
                    </text>
                </g>
            </>
        );
    } else {
        return <BarItem {...props} />;
    }
};

function TaxTooltip(props: BarTooltipProps<{ increase_percent: number; increase_absolute: number; label: string }>) {
    const increasePercentage = percentageToString(props.data.increase_percent);
    const increaseAbsolute = absoluteToString(props.data.increase_absolute);

    return (
        <Tooltip>
            <div>
                {props.id ? `${props.id} - ${props.indexValue}: ` : ""}
                {props.value ? absoluteToString(props.value) : "No data"}
            </div>
            {props.data.label !== "Status quo" && <div>{`Total increase in tax revenues: ${increaseAbsolute} (${increasePercentage})`}</div>}
        </Tooltip>
    );
}

function CoreChart() {
    const { results } = useContext(FormBContext);
    if (results && results.taxRevenueData) {
        const taxRevenueData = results.taxRevenueData.map((d) => {
            return {
                ...d,
                total: absoluteToString(d[exciseTaxSugarKey] + d[exciseTaxSaltKey] + d[vatKey]),
            };
        });

        return (
            <BarChart
                data={taxRevenueData}
                keys={[vatKey, exciseTaxSugarKey, exciseTaxSaltKey]}
                indexBy="label"
                margin={margins}
                padding={padding}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={[redColor, lightRedColor, lightRedColor]}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                axisLeft={null}
                enableGridY={false}
                labelSkipWidth={12}
                labelSkipHeight={labelSkipHeight}
                labelTextColor={({ data }) => {
                    if (data.id === 'VAT') {
                      return '#fff'; 
                    } else {
                      return '#000'; 
                    }
                  }}
                label={(d: ComputedDatum<any>) => {
                    return d.value && Math.abs(d.value) > 0.001 ? absoluteToString(d.value) : "";
                }}
                theme={{
                    fontSize:16,
                    textColor:'#474747',
                }}
                // @ts-ignore
                barComponent={BarWithTotals}
                tooltip={TaxTooltip}
            />
        );
    } else {
        return <div> No data </div>;
    }
}

export const TaxRevenuesChart = () => {
    const { selectedNutrient } = useContext(FormBContext);
    return (
        <div style={{ height: "465px", width: "100%" }}>
            <CoreChart />
            <div className="legend">
                <div className="rect redRect"></div>
                <span>VAT</span>
                {selectedNutrient === NutrientType.Sugar && (
                    <>
                        <div className="rect redRectOpa30"></div>
                        <span> Sugar Tax</span>
                    </>
                )}
                {selectedNutrient === NutrientType.Salt && (
                    <>
                        <div className="rect redRectOpa30"></div>
                        <span> Salt Tax</span>
                    </>
                )}
            </div>
        </div>
    );
};
