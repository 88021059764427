import React, { useContext } from 'react';
import { NutrientType } from '../../api/modelBObjects';
import { orangeColor } from '../../components/ChartColors';
import Tooltip from '../../components/Tooltip';
import { FormBContext } from '../../context/FormBContext';
import { NonZeroToPercentString } from '../../utils/DisplayString';
import BarChart from '../../widgets/BarChart';


function CoreChart() {
    const { results } = useContext(FormBContext)
    if (results && results.IngredientAmountsData) {
        const averagePriceChangeData = results.averagePriceChangeData;
        return <BarChart
            // @ts-ignore
            data={averagePriceChangeData}
            keys={[
                'Sugar Tax',
                'Salt Tax'
            ]}
            axisMarginHigh
            indexBy="label"
            margin={{ top: 20, right: 0, bottom: 60, left: 50 }}
            padding={0.3}
            groupMode="stacked"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={[orangeColor, orangeColor]}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                format: value => `${Number(value)} %`,
            }}
            enableGridX={false}
            enableGridY={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={'white'}
            label={d => NonZeroToPercentString(d.value)}

            theme={{
                fontSize:16,
                textColor:'#474747',
            }}

            tooltip={e =>
                <Tooltip>
                    {e.id ? `${e.id} - ${e.indexValue}: ` : ""}
                    {e.value
                        ? `${e.value.toFixed(2)}%`
                        : "No data"}
                </ Tooltip>
            }
        />
    }
    else {
        return <div> No data </div>;
    }
}

export const AveragePriceChangeChart = () => {
    const { selectedNutrient } = useContext(FormBContext)
    return <div style={{ width: "100%" }}>
        <div style={{ height: "465px" , padding:'14px'}}>
        <CoreChart />
        </div>
        <div className='legend'>
            {selectedNutrient === NutrientType.Sugar && <><div className='rect orangeRect'></div> <span>Sugar Tax</span></>}
            {selectedNutrient === NutrientType.Salt && <><div className='rect orangeRect'></div> <span>Salt Tax</span></>}
        </div>
    </div>
}
