import React, { useCallback, useContext, useEffect, useState } from "react";
import { worldCountries } from "./WorldCountries";
import { FormAContext } from "../../context/FormAContext";
import { europeBounds, europeCountries as europeCountriesDefaults } from "./EuropeCountries";
import { africaBounds, africaCountries as africaCountriesDefaults } from "./AfricaCountries";
import { LatLngBoundsExpression } from "leaflet";
import { Box, Grid, MenuItem, Select, SelectChangeEvent, Stack, Tab, Tabs, Typography } from "@mui/material";

const continents = ["Europe", "Africa"];

type ContinentData = {
    countryData: any[]; // This is the type (or lack of) used in the library, so we use `any` here too.
    bounds: LatLngBoundsExpression;
};

const getCountrySelectionState = (countryToCheck: string, enabledCountries: Array<string>, selectedCountry: string | null): number => {
    if (countryToCheck === selectedCountry) {
        return 2;
    } else {
        return enabledCountries.includes(countryToCheck) ? 1 : 0;
    }
};

// Europe
function europeFeatures(enabledCountries: Array<string>, selectedCountry: string | null) {
    return worldCountries.features
        .filter((f) => Object.keys(europeCountriesDefaults).includes(f.id))
        .map((f) => {
            return { ...f, value: getCountrySelectionState(f.id, enabledCountries, selectedCountry) };
        });
}

//Africa
function africaFeatures(enabledCountries: Array<string>, selectedCountry: string | null) {
    return worldCountries.features
        .filter((f) => Object.keys(africaCountriesDefaults).includes(f.id))
        .map((f) => {
            return { ...f, value: getCountrySelectionState(f.id, enabledCountries, selectedCountry) };
        });
}

const initialContinentState: ContinentData = {
    countryData: [],
    bounds: europeBounds,
};

export const MapsA = () => {
    const contextA = useContext(FormAContext);
    const { isDisabled, country, countries, setCountry, setIsCountrySelected } = contextA;
    const [selectedContinent, setSelectedContinent] = useState<"Africa" | "Europe">("Europe");
    const [continentData, setContinentData] = useState<ContinentData>(initialContinentState);

    const handleEurope = useCallback(
        (country: string | null) => {
            setContinentData({
                countryData: europeFeatures(countries.europe, country),
                bounds: europeBounds,
            });
        },
        [setContinentData, countries]
    );

    const handleAfrica = useCallback(
        (country: string | null) => {
            setContinentData({
                countryData: africaFeatures(countries.africa, country),
                bounds: africaBounds,
            });
        },
        [setContinentData, countries]
    );

    useEffect(() => {
        if (selectedContinent === "Europe") {
            handleEurope(country);
        } else {
            handleAfrica(country);
        }
    }, [handleAfrica, handleEurope, selectedContinent, country]);

    const handleCountrySelect = (e: SelectChangeEvent) => {
        setCountry(e.target.value);
        setIsCountrySelected(true);
    };

    const filteredCountries = useCallback(() => {
        return Array.from(continentData.countryData.filter(({ id }) => countries[selectedContinent.toLowerCase()].includes(id))).sort((a, b) => {
            const countryA = a.properties.name.toUpperCase(); // Convert names to uppercase for case-insensitive comparison
            const countryB = b.properties.name.toUpperCase();
            
            if (countryA < countryB) {
                return -1;
            }
            if (countryA > countryB) {
                return 1; 
            }
            return 0; 
        })
    }, [continentData, countries, selectedContinent]);

    return (
        <>
            <Grid item xs={6}>
                <Stack gap="8px" height="100%">
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "19px",
                            textAlign: "left",
                            color: "#363636",
                        }}
                    >
                        Select continent
                    </Typography>

                    <Box
                        sx={{
                            width: "100%",
                            borderRadius: "5px",
                            border: "1px solid #797979",
                            boxShadow: "0px 4px 4px 0px #00000040",
                        }}
                    >
                        <Tabs
                            value={selectedContinent}
                            onChange={(event: React.SyntheticEvent, newValue: "Africa" | "Europe") => {
                                setSelectedContinent(newValue);
                            }}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                        >
                            {continents.map((continent) => (
                                <Tab key={continent} value={continent} label={continent} sx={{ flex: "1" }} />
                            ))}
                        </Tabs>
                    </Box>
                </Stack>
            </Grid>

            <Grid item xs={6}>
                <Stack gap="8px" height="100%">
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "19px",
                            textAlign: "left",
                            color: "#363636",
                        }}
                    >
                        Select country
                    </Typography>
                    <Select
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                        value={country}
                        sx={{
                            width: "100%",
                            "& > .MuiOutlinedInput-input": {
                                padding: "14px 14px",
                            },
                        }}
                        onChange={handleCountrySelect}
                        displayEmpty
                        inputProps={{ "aria-label": "Select country" }}
                    >
                        {!country && (
                            <MenuItem disabled value="" sx={{color:'rgba(0, 0, 0, 0.38)'}}>
                                Select country
                            </MenuItem>
                        )}
                        {continentData.countryData &&
                            filteredCountries().map((option, id: number) => (
                                <MenuItem key={id} value={option.id}>
                                    {option.properties.name}
                                </MenuItem>
                            ))}
                    </Select>
                </Stack>
            </Grid>
        </>
    );
};
