import React, { useState } from 'react';
import FormBContextProvider from '../../context/FormBContext';
import { CalculateButtonB } from '../../components/Buttons/CalculateButtonB';
import { ChartsContainer } from '../../components/Models/ModelB/ChartsContainer';
import { InputsContainer } from '../../components/Models/ModelB/InputsContainer';
import './ModelB.scss';
import { useTranslation } from 'react-i18next';
import LayoutPanel from '../../templates/LayoutPanel';
import { MapsB } from '../../charts/Maps/CountrySelectorB';

const ModelB = () => {
    const { t } = useTranslation();
    const [isCalculated, setIsCalculated] = useState(false);

    return (
        <FormBContextProvider>
            <LayoutPanel>
                <div className='modelBComponent' >
                    {!isCalculated && <div className='topContainer'>
                        <p className='modelBPageTitle'>{t("modelB.pageTitle")}</p>
                        <MapsB />
                        <InputsContainer />

                        <div className='modelBRightContainer'>
                            <div className='calculateButtonContainer' >
                                {!isCalculated && <CalculateButtonB setIsCalculated={setIsCalculated} />}
                            </div>
                        </div>
                    </div>}
                    <ChartsContainer isCalculated={isCalculated} setIsCalculated={setIsCalculated} />
                </div>
            </LayoutPanel>
        </FormBContextProvider>
    );
}
export default ModelB;
