import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Paths from "../Paths";

import Button from "@mui/material/Button";
import logo from "../assets/logo-white.png";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import "./Header.scss";

export const Header = (props) => {
    const { t } = useTranslation();

    const logout = () => (document.location.href = Paths.logout.path);
    return (
        <div className="header sticky">
            <div className="headerContainer">
                <Link className="breadCumbsLink" to={Paths.root.path}>
                    <img className="navLogo" src={logo} alt="Coca Cola Logo" />{" "}
                </Link>
                <div className="headerLanguageGroup">
                    <Button size="small" variant="outlined" className="headerBtn" onClick={logout}>
                        {t("header.logout")}
                        <ExitToAppIcon sx={{ ml: "8px" }} />
                    </Button>
                </div>
            </div>
        </div>
    );
};
