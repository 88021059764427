export type LevyTier = {
    threshold: number | null,
    rate: number | null,
}

export type VariableLevies = {
    base: number | null,
    tiers: Array<LevyTier>
}

export type FrontEndLevies = {
    base: number | null,
    tiers: Map<number, LevyTier>
}

export type ModelBType = {
    country: string,
    categories: Array<string>,
    elasticity: Map<string, number>,
    vat: number | null,
    salt_taxes: VariableLevies,
    sugar_taxes: VariableLevies
}

export enum NutrientType { Sugar = "sugar", Salt = "salt" }

const sugarTiers = new Map();
sugarTiers.set(0, { threshold: 5, rate: 0.05 })
sugarTiers.set(1, { threshold: 8, rate: 0.1 })

export const defaultSugarTaxes: FrontEndLevies = {
    base: 0.0,
    tiers: sugarTiers
}

const saltTiers = new Map();
saltTiers.set(0, { threshold: 1.5, rate: 0.05 })
saltTiers.set(1, { threshold: 3, rate: 0.1 })
export const defaultSaltTaxes: FrontEndLevies = {
    base: 0.0,
    tiers: saltTiers
}
