import { Clear } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { NumberInput } from '../../Inputs/NumberInput';

type Props = {
    lowerThreshold?: number | null,
    lowerThresholdChanged?: (threshold: number | null) => void,
    upperThreshold?: number | null,
    rate: number | null,
    rateChanged: (rate: number | null) => void,
    removeable?: boolean,
    onRemove?: () => void,
}

type TierProps = {
    lowerThreshold?: number | null,
    lowerThresholdChanged?: (threshold: number | null) => void,
    upperThreshold?: number | null,
    width?: string,
    numberInputWidth?: string,
}

type RateProps = {
    rate: number | null,
    rateChanged: (rate: number | null) => void,
    width?: string,
    numberInputWidth?: string,
}

const TierElement = (props: TierProps) => {
    const {
        lowerThreshold, lowerThresholdChanged,
        upperThreshold, numberInputWidth
    } = props;

    const { t } = useTranslation();
    return <>
        { // range: lowerThreshold - upperThreshold
            lowerThreshold !== undefined && upperThreshold !== undefined && lowerThresholdChanged &&
            <>
                <Box width={numberInputWidth} minWidth={numberInputWidth} margin="auto">
                    <NumberInput value={lowerThreshold} onChange={lowerThresholdChanged} forceUpdate={lowerThreshold ?? 0} isNonZero={true} isPositive={true} />
                </Box>
                <Box textAlign="center" width="1em" minWidth="1em" margin="auto">
                    {'-'}
                </Box>
                <Typography width={numberInputWidth} minWidth={numberInputWidth} margin="auto">
                    {upperThreshold?.toFixed(2)}
                </Typography>
            </>
        }
        { // < upperThreshold
            lowerThreshold === undefined && upperThreshold !== undefined &&
            <>
                <Box width={numberInputWidth} minWidth={numberInputWidth} margin="auto" />
                <Box textAlign="center" width="1em" minWidth="1em" margin="auto">
                    {'<'}
                </Box>
                <Typography width={numberInputWidth} minWidth={numberInputWidth} margin="auto">
                    {upperThreshold?.toFixed(2)}
                </Typography>
            </>
        }
        { // > lowerThreshold
            lowerThreshold !== undefined && upperThreshold === undefined && lowerThresholdChanged &&
            <>
                <Box width={numberInputWidth} minWidth={numberInputWidth} margin="auto">
                    <NumberInput value={lowerThreshold} onChange={lowerThresholdChanged} forceUpdate={lowerThreshold ?? 0} isNonZero={true} isPositive={true} />
                </Box>
                <Box textAlign="center" width="1em" minWidth="1em" margin="auto">
                    {'<'}
                </Box>
                <Box width={numberInputWidth} minWidth={numberInputWidth} margin="auto" />
            </>
        }
        { // any threshold needs the text label
            (lowerThreshold !== undefined || upperThreshold !== undefined) &&
            <Typography whiteSpace="nowrap" margin="auto">
                {t("modelB.gramsPer100ml")}
            </Typography>
        }
    </>
}

const RateElement = ({ rate, rateChanged, width, numberInputWidth }: RateProps) => {
    const { t } = useTranslation();

    return <>
        <Typography textAlign="center" minWidth="1em" whiteSpace="nowrap" margin="auto">
            {t("sideBar.inputCurrency")}
        </Typography>
        <Box maxWidth={'123px'} flex='1' minWidth={'48px'} margin="auto">
            <NumberInput value={rate} onChange={rateChanged} forceUpdate={rate ?? 0} />
        </Box>
        <Typography whiteSpace="nowrap" margin="auto">
            {t("sideBar.inputPerLt")}
        </Typography>
    </>
}

export const TaxTier = (props: Props) => {
    const {
        lowerThreshold, lowerThresholdChanged,
        upperThreshold,
        rate, rateChanged,
        removeable = true, onRemove,
    } = props;

    const numberInputWidth = "3em"

    return <Stack direction="row" alignItems="center" alignContent="space-evenly" >
        <TierElement
            numberInputWidth={numberInputWidth}
            lowerThreshold={lowerThreshold}
            lowerThresholdChanged={lowerThresholdChanged}
            upperThreshold={upperThreshold} />
        <RateElement numberInputWidth={numberInputWidth} rate={rate} rateChanged={rateChanged} />
        {removeable &&
            <IconButton color="error" onClick={onRemove}>
                <Clear />
            </IconButton>
        }
    </Stack >
}
