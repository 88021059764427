import React from 'react';

import { scaleLinear } from 'd3-scale';
import { BarSvgProps, ResponsiveBar } from "@nivo/bar";

function getMinAndMaxValues(props: any) {
    let max = -Infinity;
    let min = Infinity;
    if (props.groupMode === "grouped") {
        for (const d of props.data) {
            for (const key of props.keys) {
                if (d[key] !== undefined) {
                    max = Math.max(d[key], max);
                    min = Math.min(d[key], min);
                }
            }
        }
    }
    else {
        for (const d of props.data) {
            let total = 0;
            for (const key of props.keys) {
                if (d[key] !== undefined) {
                    total += d[key];
                }
            }
            max = Math.max(total, max);
            min = Math.min(total, min);
        }
    }
    return { max, min };
}

function computeTickSize(valueScale: any, minValue: number, maxValue: number, min: number, max: number, tickCount) {
    let scale = scaleLinear()
        .domain([minValue, maxValue])

    const ticks = typeof tickCount === 'number' ? scale.ticks(tickCount) : scale.ticks();

    return Math.abs(ticks[1] - ticks[0]);
}

interface Props extends Omit<BarSvgProps<any>, "height" | "width"> {
    axisMarginLow?: boolean,
    axisMarginHigh?: boolean
}

export default function BarChart(props: Props) {
    let { max, min } = getMinAndMaxValues(props);

    let maxValue = max
    if (maxValue < 0) {
        maxValue = 0;
    }

    let minValue = min;
    if (minValue > 0) {
        minValue = 0;
    }

    let valueAxis;
    if (props.layout === "horizontal") {
        valueAxis = props.axisBottom;
    }
    else {
        valueAxis = props.axisLeft;
    }
    if (!valueAxis) {
        valueAxis = {};
    }

    if (valueAxis.tickValues === undefined) {
        valueAxis.tickValues = 8;
    }
    let tickCount = valueAxis.tickValues;

    let tickSize = computeTickSize(
        props.valueScale ? props.valueScale : { type: 'linear' },
        minValue,
        maxValue,
        min,
        max,
        tickCount);


    if (props.axisMarginLow) {
        minValue -= tickSize;
    }

    if (props.axisMarginHigh) {
        maxValue += tickSize;
    }

    return <ResponsiveBar
        maxValue={maxValue}
        minValue={minValue}
        {...props} />;
}
