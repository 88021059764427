import React, { useContext, useEffect, useState } from "react";
import { orangeColor, blackColor } from "../../components/ChartColors";
import Tooltip from "../../components/Tooltip";
import { FormBContext, NameTaxTypeValueTyped } from "../../context/FormBContext";
import { NonZeroToDisplayString, NonZeroToPercentString } from "../../utils/DisplayString";
import BarChart from "../../widgets/BarChart";
import { NutrientType } from "../../api/modelBObjects";
import { BarDatum, BarItem, BarItemProps } from '@nivo/bar';

type Props = {
    type?: "constant";
};


const CustomBarComponent = (props: BarItemProps<BarDatum>) => {
   
    const bar = props.bar;

    const {x , y, width , height , data} = bar

    return <>
            <BarItem {...props} />
            <g>
            <text
                x={x + width / 2}
                y={y + height + 10} // the important bit!!
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: 12,
                    pointerEvents: "none",
                    fill: "#000",
                    opacity: 0.75,
                }}
            >
                {data.id}
            </text>
            </g>
        </>
}

function zipChangeConsumption(data: Array<NameTaxTypeValueTyped>, selectedNutrient: NutrientType) {
    const dataForTaxType = data.filter((item) => item.taxType.toLowerCase().includes(selectedNutrient.toLowerCase()));
    const salt = dataForTaxType.find((item) => item.name === "salt")?.value;
    const sugar = dataForTaxType.find((item) => item.name === "sugar")?.value;
    return [{ selectedNutrient, Salt: salt, Sugar: sugar }];
}

function CoreChart({ type }: Props) {
    const { selectedNutrient, rawData } = useContext(FormBContext);
    const [data, setData] = useState<any[] | null>(null);

    useEffect(() => {
        if (rawData) {
            if (type === "constant") {
                setData(zipChangeConsumption(rawData.changeConsumptionDataAbsolute, selectedNutrient));
            } else {
                setData(zipChangeConsumption(rawData.changeConsumptionData, selectedNutrient));
            }
        } else {
            setData(null);
        }
    }, [rawData, selectedNutrient, type]);

    if (data) {
        return (
            <BarChart
                data={data}
                keys={["Sugar", "Salt"]}
                indexBy={"selectedNutrient"}
                margin={{ top: 10, right: 100, bottom: 70, left: 100 }}
                padding={0.1}
                groupMode={"grouped"}
                axisMarginLow
                colors={[orangeColor, blackColor]}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: "middle",
                    format: (value) => "",
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: "middle",
                    format: (value) => (type === "constant" ? `${Number(value)}` : `${Number(value)} %`),
                }}
                enableGridX={false}
                enableGridY={false}
                innerPadding={30}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={"white"}
                label={(d) => (type === "constant" ? NonZeroToDisplayString(d.value, 1, "") : NonZeroToPercentString(d.value))}
                theme={{
                    fontSize: 16,
                    textColor: "#474747",
                }}
                barComponent={CustomBarComponent}
                tooltip={(e) => (
                    <Tooltip>
                        {e.id ? `${e.id}: ` : ""}
                        {e.value ? (type === "constant" ? `${e.value.toFixed(1)}` : `${e.value.toFixed(2)}%`) : "No data"}
                    </Tooltip>
                )}
            />
        );
    } else {
        return <div> No data </div>;
    }
}

export const NutrientsChart = ({ type }: Props) => {
    return (
        <div style={{ height: "465px", width: "100%" }}>
            <CoreChart type={type} />
            <div className="legend">
                <div className="rect orangeRect"></div> <span>Sugar</span>
                <div className="rect blackRect"></div>
                <span>Salt</span>
            </div>
        </div>
    );
};
