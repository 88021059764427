import React, { useState } from 'react';
import FormAContextProvider from '../../context/FormAContext';

import { useTranslation } from 'react-i18next';
import { CalculateButtonA } from '../../components/Buttons/CalculateButtonA';
import { ChartsContainer } from '../../components/Models/ModelA/ChartsContainer';
import ModelAInputGroup from '../../components/Models/ModelA/ModelAInputGroup';
import './ModelA.scss';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import LayoutPanel from '../../templates/LayoutPanel';
import { MapsA } from '../../charts/Maps/CountrySelectorA';

const ModelA = () => {
    const [isCalculated, setIsCalculated] = useState(false);
    const { t } = useTranslation();

    return (
        <FormAContextProvider>
            <LayoutPanel>
                <div className="modelAComponent" >
                    {!isCalculated && <div className='topContainer'>
                        <p className='modelAPageTitle'>{t("modelA.pageTitle")}</p>

                        <Paper variant='outlined' sx={{
                            width: '733px',
                            padding: '24px',
                            borderRadius: '12px',
                            border: '1px solid #797979',          
                            boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2)',
                            p: "24px",
                            m: "auto"
                        }}>
                            <Typography sx={{
                                fontSize: '20px',
                                fontWeight: '600',
                                lineHeight: '24px',
                                textAlign: 'left',
                                color: '#262626',
                                mb: "8px"

                            }}>Effect on beverage price</Typography>

                            <Divider variant='fullWidth' />

                        <Grid container columnSpacing="16px" mt="33px">
                            <MapsA />
                        </Grid>

                            <div className='inputContainer'>
                                <ModelAInputGroup ></ModelAInputGroup>
                                <div className='showResultsButtonContainer'>
                                    {!isCalculated && <CalculateButtonA setIsCalculated={setIsCalculated} />}
                                </div>
                            </div>
                        </Paper>
                    </div>}
                    <ChartsContainer setIsCalculated={setIsCalculated} isCalculated={isCalculated} />
                </div>
            </LayoutPanel>
        </FormAContextProvider>
    );
};
export default ModelA;